import * as React from "react"
import {observer} from "mobx-react"
import {current_user} from "../state/index"
import {debug_state} from "../state/debug_state"
import {IconButton} from "@cling/lib.web.mdc"

export const Debug = observer(() => {
    const [mode, set_mode] = React.useState<"minimized" | "maximized">("minimized")
    const maximize = React.useCallback(() => {
        set_mode("maximized")
    }, [])
    const minimize = React.useCallback(() => {
        set_mode("minimized")
    }, [])
    const copy = React.useCallback(() => {
        const range = document.createRange()
        const pre_error = document.querySelector("#debug_error")!
        range.setStart(pre_error.firstChild!, 0)
        range.setEndBefore(pre_error.firstChild!.nextSibling!)
        const selection = getSelection()!
        selection.removeAllRanges()
        selection.addRange(range)
        document.execCommand("copy")
        selection.removeAllRanges()
    }, [])
    if (
        !(
            cling.dev ||
            current_user.account_attributes.email.endsWith("@cling.com") ||
            current_user.account_attributes.email.endsWith("@flunder.io")
        )
    ) {
        return null
    }
    if (mode === "maximized") {
        return (
            <pre className="debug__maximized" id="debug_error">
                {debug_state.errors_and_log_history || "No errors."}
                <div className="debug__buttons">
                    <IconButton icon="content_copy" secondary onClick={copy} />
                    <IconButton icon="close" secondary onClick={minimize} />
                </div>
            </pre>
        )
    }
    const {offline, num_errors} = debug_state
    return (
        <div className="debug" data-test-ignore onClick={maximize}>
            {offline && <div>Offline!</div>}
            {num_errors > 0 && (
                <div className="debug__error">
                    {num_errors === 1 ? "1 Error" : num_errors + " Errors"}
                </div>
            )}
        </div>
    )
})
