import * as React from "react"
import {observer} from "mobx-react"
import {BoardContext} from "../board_context"
import {sensors} from "../debug/sensors"
import {profiler} from "../profiler"
import {ui_state} from "../state/index"
import {assert_never} from "@cling/lib.shared.utils"
import {log} from "@cling/lib.shared.logging"
import {React_lazy, React_suspense} from "@cling/lib.web.lazy_load/suspense"
const CalendarView = React_lazy(
    () => import(process.env.F_PUBLIC_LAZY || "@cling/client.web_app/board/calendar_view"),
)
const SearchResult = React_lazy(
    () => import(process.env.F_PUBLIC_LAZY || "@cling/client.web_app/search/search_result"),
)
const ColumnsView = React_lazy(() => import("@cling/client.web_app/board/columns_view"))

export const BoardContainer = observer(() => {
    const {
        current_board: {board, display_version},
    } = React.useContext(BoardContext)
    React.useEffect(() => {
        NProgress.done()
        if (!board) {
            return
        }
        if (display_version === "latest") {
            profiler.on_board_mounted(board)
            sensors.board_shown(board)
        }
        log.debug("Rendering board", {
            board_uid: board.uid,
            display_version,
            board_version: board.version,
            view: ui_state.main_view,
        })
    }, [board, display_version])
    if (ui_state.main_view === "columns") {
        if (ui_state.search_state.all_boards) {
            return (
                <React_suspense>
                    <SearchResult key="search" />
                </React_suspense>
            )
        }
        return (
            <React_suspense>
                <ColumnsView />
            </React_suspense>
        )
    } else if (ui_state.main_view === "calendar") {
        return (
            <React_suspense>
                <CalendarView />
            </React_suspense>
        )
    } else {
        throw assert_never(ui_state.main_view)
    }
})
