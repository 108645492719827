import {init as init_download} from "../misc/download"
import {
    Account,
    AccountAnalytics,
    Board,
    BoardType,
    Client,
    CLING_ANONYMOUS,
    create_SessionUID,
    derive_URLUID,
    MediaInfo,
    Locale,
    ReportUserEventRequest,
    SyncEntityType,
    URLInfo,
    SourceCampaign,
    SourceCampaignType,
} from "@cling/lib.shared.model/model"
import {load_js} from "@cling/lib.web.lazy_load"
import * as React from "react"
import {render} from "preact"
import * as state from "../state/index"
import {Buffer} from "buffer"
import {assert, nop, not_null} from "@cling/lib.shared.utils"
import {init as init_thumbnails} from "@cling/lib.web.resources/thumbnails"
import {CLING_ANONYMOUS_FULL_ACCOUNT, from_buffer, to_buffer} from "@cling/lib.shared.model"
import {
    account_resource,
    board_info_resource,
    CurrentUser,
    init as init_resources,
} from "@cling/lib.web.resources"
import {init as init_sync_with_worker} from "./sync_with_worker"
import type {UID} from "@cling/lib.shared.types/common"
import {init as init_i18n} from "@cling/lib.web.i18n"
import {init as init_analytics, parse_source_campaign} from "@cling/lib.web.analytics"
import {is_avif_supported, is_webp_supported, running_on_mobile_device} from "@cling/lib.web.utils"
import {report_error} from "@cling/lib.shared.debug"
import {App} from "../app"
import type {LocalCache} from "@cling/lib.web.resources/local_cache"
import {log} from "@cling/lib.shared.logging"
import {call_function, init as init_faas} from "@cling/lib.shared.faas"
import {init as init_intercom} from "../misc/intercom"
import {init_dev, misc_init, preload_assets, set_global_css_classes} from "./startup_common"
import {set_fatal_error_url_params} from "@cling/lib.web.utils/fatal_error_url"
import {init_log_event_reporting} from "./init_logging"
import {query_param} from "@cling/lib.web.utils/query_param"
import {goto_board, is_cling_hp} from "../utils"
import {board_name} from "../board/board_name"
import p_limit from "p-limit"
import {BoardSearch} from "@cling/lib.shared.search/board_search"
import {inject_cling_hp_code} from "../misc/cling_hp"
import {ui_actions} from "../state/index"
import {safe_session_storage} from "@cling/lib.web.utils/safe_storage"

const report_user_event_queue = p_limit(1)

export async function start_public_board() {
    // There is no need to keep this information and we don't want to
    // raise false positive "alerts" by users that we store something in session storage.
    safe_session_storage.removeItem("ttfl_start")
    parse_source_campaign()
    const user_locale = query_param("locale", is_cling_hp() || navigator.language) as Locale
    cling.public_board_in_app = !!query_param("in_app")
    const cling_hp_info_page = query_param("p")
    // Get rid of any parameters or hashes.
    window.history.pushState(
        "",
        document.title,
        `${location.protocol}//${location.host}${location.pathname}`,
    )
    const session_uid = create_SessionUID()
    set_fatal_error_url_params({s: session_uid, a: CLING_ANONYMOUS})
    const {frontend_only, dev} = init_dev()
    misc_init({dev})
    set_global_css_classes()
    document.body.classList.add("public")
    if (location.hostname.startsWith("dev-")) {
        ;(window as any).test_helper = {load_js, goto_board}
    }
    if (frontend_only) {
        await init_frontend_only()
    }
    const await_before_render: Promise<void>[] = []
    preload_assets({await_before_render})
    const current_user = new CurrentUser({
        account: CLING_ANONYMOUS_FULL_ACCOUNT.account,
        account_attributes: CLING_ANONYMOUS_FULL_ACCOUNT.account_attributes,
        account_analytics: new AccountAnalytics({
            uid: CLING_ANONYMOUS,
            version: 1,
            board_last_seen: [],
        }),
        account_settings: CLING_ANONYMOUS_FULL_ACCOUNT.account_settings,
        account_auth: CLING_ANONYMOUS_FULL_ACCOUNT.account_auth,
        is_new: false,
    })
    await init_i18n(user_locale)
    current_user.account.locale = user_locale
    const client = running_on_mobile_device() ? Client.web_app_mobile : Client.web_app_desktop
    init_analytics({
        client,
        client_version: cling.version,
        current_user_info: () => ({
            tracking_id: undefined,
            locale: current_user.account.locale,
            feature_switches: current_user.account_attributes.feature_switches,
            team_uids: [],
            plan: undefined,
            cohort_date: current_user.account.first_change.date,
        }),
        send_user_event:
            frontend_only ||
            (is_cling_hp() &&
                location.hostname !== "cling.com" &&
                // We need events reported for `/../whats-new.html` because a) it serves
                // a project board and not a board from cling.com and b) we use it in tests.
                !location.href.endsWith("/whats-new.html"))
                ? nop
                : (user_event, browser_env) => {
                      browser_env.document_referrer = document.referrer.startsWith(
                          `https://${location.host}`,
                      )
                          ? ""
                          : document.referrer
                      const board_info = user_event.board_uid
                          ? board_info_resource.read(user_event.board_uid)
                          : undefined
                      if (board_info && is_cling_hp()) {
                          browser_env.document_title = board_name(board_info)
                      } else if (board_info && board_info.indexed_by_search_engines) {
                          browser_env.document_title = `[pb] ${board_name(board_info)}`
                          if (!user_event.source_campaign) {
                              user_event.source_campaign = new SourceCampaign({
                                  type: SourceCampaignType.public_board,
                              })
                          }
                      } else {
                          browser_env.document_title = "__public_board"
                          if (!user_event.source_campaign) {
                              user_event.source_campaign = new SourceCampaign({
                                  type: SourceCampaignType.public_board,
                              })
                          }
                      }
                      report_user_event_queue(() =>
                          call_function(
                              new ReportUserEventRequest({
                                  user_events: [user_event],
                                  browser_env,
                              }),
                          ).catch(report_error),
                      ).catch(report_error)
                  },
        current_board_uid: () => ui_state.current_board_uid,
    })
    init_log_event_reporting(client)
    const faas_trace_provider = () =>
        `|web_app:${process.env.VERSION}:${CLING_ANONYMOUS}:${session_uid}|`
    if (!frontend_only) {
        init_faas({
            request_modifier: (req) => {
                req.headers["x-cling-faas-trace"] = faas_trace_provider()
            },
            on_401: () => {
                reload("Got a 401")
            },
        })
    }
    init_download(faas_trace_provider, () => true)
    const {caches} = await init_sync_with_worker(
        // We can safely ignore all updates from worker because there is no worker :-)
        () => true,
    )
    const {request_sync, board} = request_sync_factory(caches)
    init_resources({
        current_user,
        caches,
        request_sync,
        wait_for_sync: async (uid, type) => {
            if (uid !== board.uid) {
                report_error("`wait_for_sync` should not be called for this uid/type", {uid, type})
            }
        },
    })
    const board_search = new BoardSearch(
        (board_uid) => (board_uid === board.uid ? board.board_info : undefined),
        () => undefined,
        () => account_resource.read_all(),
        CLING_ANONYMOUS,
    )
    board_search.update(board)
    const ui_state = state.init({
        current_user,
        call_search: async (query) => {
            return {matches: board_search.search(query), indexed_board_uids: [board.uid]}
        },
        publish_patch: () => {
            // Ignored - we will never send patches.
        },
    })
    ui_state.set_current_board_uid(board.uid, "latest")
    ui_state.desktop_board_chooser_state = "hidden"
    assert(state.current_user, "`state.current_user` has not been set yet.")
    const thumbnail_ext = (await is_avif_supported())
        ? ".avif"
        : (await is_webp_supported())
          ? ".webp"
          : ".png"
    await init_thumbnails({
        thumbnail_url(args) {
            return not_null(cling.content).thumbnail_url({...args, ext: thumbnail_ext})
        },
        image_cache_thrash_index: () => 0,
        skip_cache: true,
    })
    init_intercom({
        crm_id: undefined,
        email: undefined,
        full_name: undefined,
    })
    if (is_cling_hp()) {
        await inject_cling_hp_code({cling_hp_info_page})
    }
    await Promise.all(await_before_render)
    render(React.createElement(App), document.getElementById("root")!)
    if (cling.content?.highlighted_card_uid) {
        ui_actions.highlight_card(cling.content.highlighted_card_uid)
    }
}

function request_sync_factory(caches: Record<SyncEntityType, LocalCache<any, any>>) {
    // Prefill all caches - then there should not be anything needed to be requested.
    const content = not_null(cling.content)
    const board_buf = Buffer.from(content.board_base64, "base64")
    const board = from_buffer(Board, board_buf)
    caches[SyncEntityType.board].put(board.uid, board_buf, board)
    const board_info = board.board_info
    caches[SyncEntityType.board_info].put(board.uid, to_buffer(board_info), board_info)
    content.board_base64 = ""
    for (const x of content.accounts_base64) {
        const buf = Buffer.from(x, "base64")
        const account = from_buffer(Account, buf)
        caches[SyncEntityType.account].put(account.uid, buf, account)
    }
    content.accounts_base64 = []
    for (const x of content.media_infos_base64) {
        const buf = Buffer.from(x, "base64")
        const media_info = from_buffer(MediaInfo, buf)
        caches[SyncEntityType.media_info].put(media_info.blob_uid, buf, media_info)
    }
    content.media_infos_base64 = []
    for (const x of content.url_infos_base64) {
        const buf = Buffer.from(x, "base64")
        const url_info = from_buffer(URLInfo, buf)
        caches[SyncEntityType.url_info].put(derive_URLUID(url_info.url), buf, url_info)
    }
    content.url_infos_base64 = []
    // Delete the script-tag, we no longer need it.
    document.getElementById("cling-public-content")?.remove()
    return {
        request_sync: (uid: UID, type: SyncEntityType) => {
            log.warn("Unexpected call to `request_sync`", {uid, type: SyncEntityType[type]})
        },
        board,
    }
}

async function init_frontend_only() {
    ;(window as any).test_helper = (window as any).test_helper || {}
    await load_js(
        {src: "/c/dist/dev_test_helper.js", type: "module"},
        () => !!(window as any).test_helper.frontend_only,
    )
    const data = (window as any).test_helper.frontend_only.frontend_only_test_data(location.search)
    cling.content = {
        board_base64: to_buffer(
            data.boards.find((x: Board) => x.board_type === BoardType.dashboard),
        ).toString("base64"),
        accounts_base64: data.accounts.map((x: Account) => to_buffer(x).toString("base64")),
        media_infos_base64: data.media_infos.map((x: MediaInfo) => to_buffer(x).toString("base64")),
        url_infos_base64: data.url_infos.map((x: URLInfo) => to_buffer(x).toString("base64")),
        thumbnail_url: () => {
            throw new Error("Not needed in frontend_only mode")
        },
    }
}
