import * as React from "react"

import {classNames} from "@cling/lib.web.utils"

type Props = React.HTMLAttributes<HTMLHeadElement> & {
    children?: any
    className?: string
}

/**
 * We have to fake this, because there is no component yet in MDC.
 */
export class BottomAppBar extends React.PureComponent<Props> {
    render() {
        const {children, className, ...html_props} = this.props
        return (
            <footer
                className={classNames(
                    "mdc-top-app-bar mdc-top-app-bar--fixed mdcx-bottom-app-bar",
                    className,
                )}
                {...html_props}
            >
                <div className="mdc-top-app-bar__row">{children}</div>
            </footer>
        )
    }
}
