import type {BoardInfo} from "@cling/lib.shared.model"
import {BoardPermissions, board_resource} from "@cling/lib.web.resources"
import {running_on_mobile_device} from "@cling/lib.web.utils"
import * as React from "react"
import {is_cling_hp} from "../utils"
import {
    BottomToolbarItemsMobileBoard,
    TopToolbarItemsDesktopBoard,
    TopToolbarItemsMobileBoard,
} from "./toolbar_items_board"
import {
    BottomToolbarItemsDesktopPublicBoard,
    BottomToolbarItemsMobilePublicBoard,
    TopToolbarItemsDesktopPublicBoard,
    TopToolbarItemsMobilePublicBoard,
} from "./toolbar_items_public_board"
import {
    BottomToolbarItemsDesktopWebsite,
    BottomToolbarItemsMobileWebsite,
    TopToolbarItemsDesktopWebsite,
    TopToolbarItemsMobileWebsite,
} from "./toolbar_items_website"

export const TopToolbarItems = ({
    is_dashboard,
    board_info,
    permissions,
    open_drawer,
}: {
    is_dashboard: boolean
    board_info?: BoardInfo
    permissions: BoardPermissions
    open_drawer: () => void
}) => {
    if (is_cling_hp()) {
        return running_on_mobile_device() ? (
            <TopToolbarItemsMobileWebsite board_info={board_info} />
        ) : (
            <TopToolbarItemsDesktopWebsite board_info={board_info} />
        )
    }
    if (process.env.F_PUBLIC) {
        return running_on_mobile_device() ? (
            <TopToolbarItemsMobilePublicBoard is_dashboard={is_dashboard} board_info={board_info} />
        ) : (
            <TopToolbarItemsDesktopPublicBoard board_info={board_info} />
        )
    }
    return running_on_mobile_device() ? (
        <TopToolbarItemsMobileBoard
            is_dashboard={is_dashboard}
            open_drawer={open_drawer}
            permissions={permissions}
            board_info={board_info}
        />
    ) : (
        <TopToolbarItemsDesktopBoard
            is_dashboard={is_dashboard}
            open_drawer={open_drawer}
            permissions={permissions}
            board_info={board_info}
        />
    )
}

export const BottomMobileToolbarItems = ({
    board_info,
    is_dashboard,
}: {
    is_dashboard: boolean
    board_info?: BoardInfo
}) => {
    const board = board_info && board_resource.read(board_info.uid)
    if (is_cling_hp()) {
        return <BottomToolbarItemsMobileWebsite board={board} />
    }
    if (process.env.F_PUBLIC) {
        return <BottomToolbarItemsMobilePublicBoard board={board} is_dashboard={is_dashboard} />
    }
    return <BottomToolbarItemsMobileBoard board={board} is_dashboard={is_dashboard} />
}

export const BottomDesktopToolbars = () => {
    if (process.env.F_PUBLIC && !!is_cling_hp()) {
        return <BottomToolbarItemsDesktopWebsite />
    }
    if (process.env.F_PUBLIC && !is_cling_hp()) {
        return <BottomToolbarItemsDesktopPublicBoard />
    }
    return null
}
