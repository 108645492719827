import * as React from "react"
import {observer} from "mobx-react"
import {React_lazy, React_suspense} from "@cling/lib.web.lazy_load/suspense"
import {ui_actions, ui_state} from "../state/index"
const TaskEditDialog = React_lazy(
    () => import(process.env.F_PUBLIC_LAZY || "@cling/client.web_app/card/task_edit_dialog"),
)
const SendToBoardDialog = React_lazy(
    () => import(process.env.F_PUBLIC_LAZY || "@cling/client.web_app/card/send_to_board_dialog"),
)
const CheckoutDialog = React_lazy(() => import("@cling/client.web_app/payment/checkout_dialog"))
const QuotaLimitExceededDialog = React_lazy(
    () =>
        import(
            process.env.F_PUBLIC_LAZY || "@cling/client.web_app/account/quota_limit_exceeded_dialog"
        ),
)
const AccountSettingsDialog = React_lazy(
    () =>
        import(
            process.env.F_PUBLIC_LAZY || "@cling/client.web_app/account/account_settings_dialog"
        ),
)
const ProFeatureTeaserDialog = React_lazy(
    () =>
        import(
            process.env.F_PUBLIC_LAZY || "@cling/client.web_app/account/pro_feature_teaser_dialog"
        ),
)
const ClingProTeaser = React_lazy(
    () => import(process.env.F_PUBLIC_LAZY || "@cling/client.web_app/account/cling_pro_teaser"),
)
const BoardSettingsDialogContainer = React_lazy(
    () =>
        import(
            process.env.F_PUBLIC_LAZY ||
                "@cling/client.web_app/dialogs/board_settings_dialog_container"
        ),
)
const ShareBoardDialog = React_lazy(
    () => import(process.env.F_PUBLIC_LAZY || "@cling/client.web_app/dialogs/share_board_dialog"),
)
const StartConversationDialog = React_lazy(
    () =>
        import(
            process.env.F_PUBLIC_LAZY || "@cling/client.web_app/dialogs/start_conversation_dialog"
        ),
)
const ImportDataDialog = React_lazy(
    () =>
        import(process.env.F_PUBLIC_LAZY || "@cling/client.web_app/import_data/import_data_dialog"),
)
const DiagnosticsDialog = React_lazy(
    () => import("@cling/client.web_app/dialogs/diagnostics_dialog"),
)
const TeamsDialog = React_lazy(
    () => import(process.env.F_PUBLIC_LAZY || "@cling/client.web_app/team/teams_dialog"),
)
const PromptContainer = React_lazy(() => import("@cling/client.web_app/dialogs/prompt"))
const CopyBoardDialog = React_lazy(
    () => import(process.env.F_PUBLIC_LAZY || "@cling/client.web_app/dialogs/copy_board_dialog"),
)
const AddBoardDialog = React_lazy(
    () => import(process.env.F_PUBLIC_LAZY || "@cling/client.web_app/dialogs/add_board_dialog"),
)
const ReportAbuseDialog = React_lazy(() => import("@cling/client.web_app/card/report_abuse_dialog"))

export const DialogContainer = observer(() => {
    let dialog
    if (ui_state.dialog === "new_board") {
        dialog = <AddBoardDialog />
    } else if (ui_state.dialog === "copy_board") {
        dialog = <CopyBoardDialog />
    } else if (ui_state.dialog === "board_settings") {
        dialog = <BoardSettingsDialogContainer />
    } else if (ui_state.dialog === "share_board") {
        dialog = <ShareBoardDialog />
    } else if (ui_state.dialog === "import_data") {
        dialog = <ImportDataDialog />
    } else if (ui_state.dialog === "task_editor") {
        dialog = <TaskEditDialog {...ui_state.task_edit_dialog_props!} />
    } else if (ui_state.dialog === "send_to_board") {
        dialog = <SendToBoardDialog {...ui_state.send_to_board_dialog_props!} />
    } else if (ui_state.dialog === "checkout") {
        dialog = <CheckoutDialog on_cancel={ui_actions.close_dialog} />
    } else if (ui_state.dialog === "quota_limit_exceeded_dialog") {
        dialog = <QuotaLimitExceededDialog />
    } else if (ui_state.dialog === "account_settings") {
        dialog = <AccountSettingsDialog />
    } else if (ui_state.dialog === "start_conversation") {
        dialog = <StartConversationDialog />
    } else if (ui_state.dialog === "diagnostics") {
        dialog = <DiagnosticsDialog />
    } else if (ui_state.dialog === "teams") {
        dialog = <TeamsDialog />
    } else if (ui_state.dialog === "report_abuse") {
        dialog = <ReportAbuseDialog {...ui_state.report_abuse_dialog_props} />
    }
    return (
        <React_suspense>
            {ui_state.pro_feature_teaser_dialog_props && (
                <ProFeatureTeaserDialog {...ui_state.pro_feature_teaser_dialog_props!} />
            )}
            <PromptContainer />
            {dialog}
            <ClingProTeaser />
        </React_suspense>
    )
})
