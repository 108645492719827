import * as React from "react"
import {ListItem, MenuItem, MenuItemDivider, Icon} from "@cling/lib.web.mdc"
import {i18n} from "@cling/lib.web.i18n"
import {
    BoardType,
    BoardUID,
    board_uid_type,
    board_vanity_uri,
    is_system_board_uid,
} from "@cling/lib.shared.model"

export type BoardChooserItem = {
    uid: BoardUID
    sort: {
        name: string
        date_ms: number
    }
    user_board_title: any
    user_board_title_str: string
    background_style: React.CSSProperties
    show_badge?: boolean
    disabled?: boolean
    selected?: boolean
    can_be_folded?: boolean
    archived?: boolean
    num_participants_in_video_chat: number
}

export function board_chooser_items({
    element_type,
    items,
    onSelected,
    sort_boards_alphabetically,
}: {
    element_type: "ListItem" | "MenuItem"
    items: BoardChooserItem[]
    onSelected?: (board_uid: BoardUID) => void
    sort_boards_alphabetically: boolean
}) {
    const system_boards = items.filter((x) => is_system_board_uid(x.uid))
    const normal_boards = items.filter((x) => !is_system_board_uid(x.uid))
    // Sort the boards by whether they are archived, can be folded, modification date, or name
    // based on `sort_boards_alphabetically`.
    normal_boards.sort((a, b) => {
        let res = (a.archived ? 1 : 0) - (b.archived ? 1 : 0)
        if (res !== 0) {
            return res
        }
        if (!sort_boards_alphabetically) {
            res = (a.can_be_folded ? 1 : 0) - (b.can_be_folded ? 1 : 0)
            if (res !== 0) {
                return res
            }
            res = b.sort.date_ms - a.sort.date_ms
            if (res !== 0) {
                return res
            }
        }
        return a.sort.name.localeCompare(b.sort.name)
    })
    const chooser_item = (item: BoardChooserItem, key_prefix = "") => {
        const graphic = (
            <div className="board-chooser-item__icon" style={item.background_style}>
                {item.show_badge && (
                    <div className="board-chooser-item__new-or-changed-badge mdcx-badge mdcx-badge--small mdcx-badge--secondary">
                        &nbsp;
                    </div>
                )}
            </div>
        )
        const board_type = board_uid_type(item.uid)
        const title =
            board_type === BoardType.dashboard
                ? i18n.cling_home_screen
                : board_type === BoardType.trashcan
                  ? i18n.trashcan
                  : board_type === BoardType.clipboard
                    ? i18n.clipboard
                    : item.user_board_title
        const meta =
            item.num_participants_in_video_chat > 0 ? (
                <div>
                    <Icon icon="voice_chat" primary />
                </div>
            ) : undefined
        if (element_type === "ListItem") {
            return (
                <ListItem
                    key={key_prefix + item.uid}
                    className="board-chooser-item"
                    disabled={item.disabled}
                    selected={item.selected}
                    onClick={onSelected ? () => onSelected(item.uid) : undefined}
                    data-test-id={`BoardChooser_board_${item.uid}`}
                    data-can-be-folded={item.can_be_folded}
                    value={item.uid}
                >
                    {graphic}
                    <a
                        href={board_vanity_uri(item.uid, item.user_board_title_str)}
                        onClick={on_click}
                        tabIndex={-1}
                    >
                        {title}
                    </a>
                    {meta}
                </ListItem>
            )
        }
        return (
            <MenuItem
                key={key_prefix + item.uid}
                title={title}
                className="board-chooser-item"
                disabled={item.disabled}
                graphic={graphic}
                value={item.uid}
                meta={meta}
                data-test-id={`BoardChooser_board_${item.uid}`}
                data-can-be-folded={item.can_be_folded}
            />
        )
    }
    const result = []
    if (system_boards.length > 0) {
        // Render in order "dashboard", "clipboard", "trashcan" ...
        for (const board_type of [BoardType.dashboard, BoardType.clipboard, BoardType.trashcan]) {
            const item = system_boards.find((x) => board_uid_type(x.uid) === board_type)
            if (item) {
                result.push(chooser_item(item))
            }
        }
    }
    if (normal_boards.length > 0) {
        if (system_boards.length > 1) {
            result.push(<MenuItemDivider key="regular" title={i18n.all_your_boards} />)
        }
        result.push(...normal_boards.map((x) => chooser_item(x)))
    }
    return result
}

function on_click(e: React.MouseEvent) {
    e.preventDefault()
    if (e.ctrlKey || e.metaKey) {
        // Open the board in a new window ...
        const url = e.currentTarget.getAttribute("href")
        if (url) {
            open(url, "_blank")
            e.stopPropagation()
        }
    }
}
