import * as React from "react"
import {MDCChipSet, MDCChip} from "@material/chips/index"
import {BaseComponent} from "./base"
import {classNames} from "@cling/lib.web.utils"

export class ChipSet extends BaseComponent<React.HTMLProps<HTMLDivElement>> {
    focus() {
        if (this.mdc_component_ref) {
            this.mdc_component_ref.focus()
        }
    }

    render() {
        const {children, className, ...more_props} = this.props
        return (
            <div
                className={classNames("mdc-chip-set", className)}
                role="grid"
                ref={this.add_mdc(MDCChipSet)}
                {...more_props}
            >
                {children}
            </div>
        )
    }
}

interface Props {
    small?: boolean
    icon?: string
    trailing?: boolean
    className?: string
    primary?: boolean
}

export class Chip extends BaseComponent<Props & React.HTMLProps<HTMLDivElement>> {
    render() {
        const {icon, primary, trailing, small, children, className, ...other_props} = this.props
        return (
            <div
                className={classNames("mdc-chip", className, {
                    "mdcx-chip--small": small,
                    "mdcx-chip--primary": primary,
                })}
                role="row"
                ref={this.add_mdc(MDCChip)}
                {...other_props}
            >
                <div className="mdc-chip__ripple" />
                {icon && !trailing && (
                    <i
                        className="material-icons mdc-chip__icon mdc-chip__icon--leading"
                        data-icon={icon}
                    />
                )}
                <span role="gridcell">
                    <span role="button" className="mdc-chip__primary-action" tabIndex={0}>
                        <span className="mdc-chip__text">{children}</span>
                    </span>
                </span>
                {icon && trailing && (
                    <span role="gridcell">
                        <i
                            className="material-icons mdc-chip__icon mdc-chip__icon--trailing"
                            role="button"
                            data-icon={icon}
                        />
                    </span>
                )}
            </div>
        )
    }
}
