import {
    Account,
    AccountBoardSettings,
    Board,
    BoardUID,
    Card,
    CardUID,
    Comment,
    CommentUID,
    TaskStatus,
    BoardInfo,
    AccountUID,
    CardColor,
    Team,
    PrincipalUID,
    is_AccountUID,
    is_TeamUID,
    BoardType,
    extract_linked_board_and_card_uid,
} from "@cling/lib.shared.model"
import {
    assert_never,
    fast_string_hash,
    full_name,
    flatten,
    escape_html,
} from "@cling/lib.shared.utils"
import type {SearchQuery, FieldExpression} from "./types"
import {fuzzy_matcher} from "./fuzzy_matcher"

export type SearchableTextField = "title" | "safe_html" | "url" | "file_name"

export type Match = {
    board_uid: BoardUID
    card_uid: CardUID
    comment_uid?: CommentUID
    order_value: number
    text_matches: [
        {
            field: SearchableTextField
            term: string
            /**
             * The hash of the text, in which the match was found.
             */
            text_hash: number
            /**
             * The indices of the found match(es). This can be used for highlighting.
             */
            text_indices: [number, number][]
        },
    ]
    /**
     * A score between 0 and 1 (perfect match). Field expressions always yield a score of 1. If
     * there are multiple search terms the score is a "running" average.
     */
    score: number
}

const searchable_text_fields: SearchableTextField[] = ["title", "safe_html", "url", "file_name"]

type SearchItemTexts = {[P in SearchableTextField]?: string | (() => string | undefined)}

type SearchItem = {
    board_uid: BoardUID
    card_uid: CardUID
    comment_uid?: CommentUID
    texts: SearchItemTexts
    modified_at: number
    board_archived: boolean
    board_owner: AccountUID
    board_acl_all: Set<PrincipalUID>
    card_archived?: boolean
    card_color?: CardColor
    card_created_by: AccountUID
    card_modified_by: AccountUID
    card_kind?: "note" | "link" | "file"
    task?: boolean
    task_status?: TaskStatus
    task_created_by?: AccountUID
    task_modified_by?: AccountUID
    task_assignee?: AccountUID
    task_date?: number
}

export class BoardSearch {
    private search_items = new Map<BoardUID, {last_change: Date; items: SearchItem[]}>()

    constructor(
        private board_info: (board_uid: BoardUID) => BoardInfo | undefined,
        private board_setting: (board_uid: BoardUID) => AccountBoardSettings | undefined,
        private principals: () => (Account | Team)[],
        private me_account_uid: AccountUID,
    ) {}

    /**
     * Update the search index.
     */
    update(x: Board | {board_uid: BoardUID; board_archived: boolean}) {
        if (x instanceof Board) {
            const {uid, regular_cards} = x
            const board_archived = !!this.board_setting(uid)?.archived
            const board_acl_all = new Set(x.acl.all)
            const items = []
            for (const card of regular_cards) {
                items.push(
                    this._search_item({
                        board: x,
                        source: card,
                        board_archived: board_archived,
                        board_acl_all: board_acl_all,
                    }),
                )
                for (const comment of card.comments) {
                    items.push(
                        this._search_item({
                            board: x,
                            source: comment,
                            board_archived: board_archived,
                            board_acl_all: board_acl_all,
                        }),
                    )
                }
            }
            this.search_items.set(uid, {last_change: x.last_change.date, items})
        } else {
            const {board_uid, board_archived} = x
            const y = this.search_items.get(board_uid)
            if (y && y.items.length && y.items[0].board_archived !== board_archived) {
                y.items.forEach((search_item) => (search_item.board_archived = board_archived))
            }
        }
    }

    get indexed_board_uids() {
        return [...this.search_items.keys()]
    }

    /**
     * Update the search index.
     */
    remove(board_uid: BoardUID) {
        this.search_items.delete(board_uid)
    }

    search(query: SearchQuery, opts: {limit_cards?: number} = {}): Match[] {
        const prepared_principals = this.principals().map((x) => ({
            name: x instanceof Account ? full_name(x) : x.name,
            uid: x.uid,
        }))
        let result = new Map<string, Match>()
        // Pre-calculate some values to improve search speed.
        const field_expressions = query.field_expressions.map((x) => {
            if (x.field === "modified.at" || x.field === "task.date") {
                if (x.term.range === "open") {
                    x.term.from += 1
                    x.term.to -= 1
                } else if (x.term.range === "left_closed") {
                    x.term.to -= 1
                } else if (x.term.range === "right_closed") {
                    x.term.from += 1
                } else if (x.term.range !== "closed") {
                    throw assert_never(x.term.range)
                }
            }
            return x
        })
        // First filter non-text expressions aka `field expressions`.
        let search_items = this.match_field_expressions(field_expressions, prepared_principals)
        search_items.forEach((x) =>
            result.set(`${x.board_uid}:${x.card_uid}:${x.comment_uid}`, {
                board_uid: x.board_uid,
                card_uid: x.card_uid,
                comment_uid: x.comment_uid,
                order_value: 0,
                score: 1,
                text_matches: [] as any,
            }),
        )
        // Next, filter all text-expressions.
        const text_expressions = field_expressions.filter((x) => !x.field) as {
            term: string
            quoted: boolean
            not: boolean
        }[]
        for (const text_expression of text_expressions) {
            const search_term_matches = new Map<string, Match>()
            const matcher = fuzzy_matcher(text_expression.term, {
                ignore_xml: true,
                almost_exact: text_expression.quoted,
            })
            for (const text_field of searchable_text_fields) {
                for (const search_item of search_items) {
                    const text = search_item.texts[text_field]
                    const final_text = typeof text === "function" ? text() : text
                    if (!final_text) {
                        continue
                    }
                    const matches = matcher(final_text)
                    if (matches.length) {
                        const key = `${search_item.board_uid}:${search_item.card_uid}:${search_item.comment_uid}`
                        let res = result.get(key)
                        const score = Math.max(...matches.map((x) => x.score))
                        if (!res) {
                            res = {
                                board_uid: search_item.board_uid,
                                card_uid: search_item.card_uid,
                                comment_uid: search_item.comment_uid,
                                order_value: 0,
                                score: score,
                                text_matches: [] as any,
                            }
                            result.set(key, res!)
                        } else {
                            if (res.text_matches.some((x) => x.term === text_expression.term)) {
                                // Take the best score if we already matched the term.
                                res.score = Math.max(res.score, score)
                            } else {
                                // Take "continuous average" otherwise.
                                res.score = (res!.score + score) / 2
                            }
                        }
                        res.text_matches.push({
                            field: text_field,
                            term: text_expression.term,
                            text_hash: fast_string_hash(final_text),
                            text_indices: flatten(matches.map((x) => x.indices)),
                        })
                        search_term_matches.set(key, res!)
                    }
                }
            }
            if (text_expression.not) {
                // Make all `search_items` match except the once found.
                for (const key of search_term_matches.keys()) {
                    result.delete(key)
                }
            } else {
                result = search_term_matches
            }
            search_items = search_items.filter((x) =>
                result.has(`${x.board_uid}:${x.card_uid}:${x.comment_uid}`),
            )
        }
        return this.sort_and_limit(search_items, result, query.order, opts.limit_cards)
    }

    private sort_and_limit(
        search_items: SearchItem[],
        matches: Map<string, Match>,
        order?: {field: "task.date" | "modified.at"; dir: "ASC" | "DESC"},
        limit_cards?: number,
    ): Match[] {
        let res: Match[]
        if (!order) {
            res = [...matches.values()].sort((a, b) => {
                // We normalize the scores to 1000 in order to avoid floating point errors.
                const sort_order = Math.floor(b.score * 1000) - Math.floor(a.score * 1000)
                if (sort_order === 0) {
                    // We want the sort order to be deterministic. The order of cards in a
                    // board as determined by `board.regular_cards` is deterministic.
                    // Let's make this deterministic across boards.
                    return b.board_uid.localeCompare(a.board_uid)
                }
                return sort_order
            })
        } else {
            let order_value_fn: (x: SearchItem) => number
            if (order.field === "task.date") {
                if (order.dir === "ASC") {
                    order_value_fn = (x) =>
                        x.task_date ||
                        (x.task ? Number.MAX_SAFE_INTEGER - 2 : Number.MAX_SAFE_INTEGER - 1)
                } else {
                    order_value_fn = (x) => x.task_date || (x.task ? 1 : 0)
                }
            } else if (order.field === "modified.at") {
                order_value_fn = (x) => x.modified_at
            } else {
                throw assert_never(order.field)
            }
            res = search_items.map((x) => {
                const v = matches.get(`${x.board_uid}:${x.card_uid}:${x.comment_uid}`)!
                v.order_value = order_value_fn(x)
                return v
            })
            if (order.dir === "ASC") {
                res.sort((a, b) => {
                    const sort_order = a.order_value - b.order_value
                    if (sort_order === 0) {
                        // We want the sort order to be deterministic. The order of cards in a
                        // board as determined by `board.regular_cards` is deterministic.
                        // Let's make this deterministic across boards.
                        return b.board_uid.localeCompare(a.board_uid)
                    }
                    return sort_order
                })
            } else {
                res.sort((a, b) => {
                    const sort_order = b.order_value - a.order_value
                    if (sort_order === 0) {
                        // We want the sort order to be deterministic. The order of cards in a
                        // board as determined by `board.regular_cards` is deterministic.
                        // Let's make this deterministic across boards.
                        return b.board_uid.localeCompare(a.board_uid)
                    }
                    return sort_order
                })
            }
        }
        if (limit_cards && res.length > limit_cards) {
            let num_cards = 0
            for (let i = 0; i < res.length; i++) {
                const x = res[i]
                if (!x.comment_uid) {
                    num_cards += 1
                    if (num_cards > limit_cards) {
                        res = res.slice(0, i)
                        break
                    }
                }
            }
        }
        return res
    }

    private match_field_expressions(
        query: FieldExpression[],
        prepared_principals: Array<{name: string; uid: PrincipalUID}>,
    ): SearchItem[] {
        const principal_uids_cache = new Map<string, Set<PrincipalUID>>()
        const match_principal_uids = (
            expr_term: string,
            principal_uids: PrincipalUID | PrincipalUID[],
        ) => {
            let matches = principal_uids_cache.get(expr_term)
            if (!matches) {
                if (expr_term === "me") {
                    matches = new Set([this.me_account_uid])
                } else {
                    const matcher = fuzzy_matcher(expr_term, {almost_exact: true})
                    matches = new Set(
                        prepared_principals.filter((x) => matcher(x.name).length).map((x) => x.uid),
                    )
                    if (is_AccountUID(expr_term) || is_TeamUID(expr_term)) {
                        matches.add(expr_term)
                    }
                }
                principal_uids_cache.set(expr_term, matches)
            }
            if (Array.isArray(principal_uids)) {
                return principal_uids.some((x) => matches!.has(x))
            }
            return matches.has(principal_uids)
        }
        const result: SearchItem[] = []
        const board_search_items = [...this.search_items.values()].map((x) => x.items)
        for (const search_items of board_search_items) {
            for (const search_item of search_items) {
                const archived = !!(search_item.board_archived || search_item.card_archived)
                let keep = true
                let keep_archived = !archived
                let board_matched_explicitely = false
                for (const expr of query) {
                    if (!expr.field) {
                        // This  is a text search term, nothing to do.
                        continue
                    } else if (expr.field === "board.uid") {
                        if (expr.term !== "all") {
                            keep = expr.term.includes(search_item.board_uid)
                            board_matched_explicitely = keep && !expr.not
                        }
                    } else if (expr.field === "card.uid") {
                        keep = expr.term.includes(search_item.card_uid)
                    } else if (expr.field === "archived") {
                        if (expr.term === "show") {
                            if (!expr.not) {
                                keep_archived = true
                            }
                            continue
                        } else {
                            keep = archived === (expr.term === "yes")
                            keep_archived = true
                        }
                    } else if (expr.field === "card.color") {
                        keep =
                            expr.term === "yes"
                                ? !!search_item.card_color
                                : search_item.card_color === expr.term
                    } else if (expr.field === "card.created.by") {
                        keep = match_principal_uids(expr.term, search_item.card_created_by)
                    } else if (expr.field === "card.modified.by") {
                        keep = match_principal_uids(expr.term, search_item.card_modified_by)
                    } else if (expr.field === "task") {
                        keep = search_item.task === (expr.term === "yes")
                    } else if (
                        expr.field === "task.status" ||
                        expr.field === "task.assignee" ||
                        expr.field === "task.created.by" ||
                        expr.field === "task.modified.by" ||
                        expr.field === "task.date"
                    ) {
                        // Querying a task-field only makes sense, if it is a task.
                        if (!search_item.task) {
                            keep = false
                            break
                        }
                        if (expr.field === "task.status") {
                            keep = search_item.task_status === expr.term
                        } else if (expr.field === "task.assignee") {
                            keep = match_principal_uids(expr.term, search_item.task_assignee!)
                        } else if (expr.field === "task.created.by") {
                            keep = match_principal_uids(expr.term, search_item.task_created_by!)
                        } else if (expr.field === "task.modified.by") {
                            keep = match_principal_uids(expr.term, search_item.task_modified_by!)
                        } else if (expr.field === "task.date") {
                            const {from, to} = expr.term
                            keep = from <= search_item.task_date! && to >= search_item.task_date!
                        } else {
                            assert_never(expr)
                        }
                    } else if (expr.field === "card.kind") {
                        keep = search_item.card_kind === expr.term
                    } else if (expr.field === "comment.uid") {
                        keep =
                            !!search_item.comment_uid && expr.term.includes(search_item.comment_uid)
                    } else if (expr.field === "modified.at") {
                        const {from, to} = expr.term
                        keep = from <= search_item.modified_at && to >= search_item.modified_at
                    } else if (expr.field === "board.owner") {
                        keep = expr.term.some((x) =>
                            // new Set([search_item.board_owner]).has(search_item.board_owner)
                            match_principal_uids(x, search_item.board_owner),
                        )
                    } else if (expr.field === "board.acl.all") {
                        keep = false
                        for (const x of search_item.board_acl_all) {
                            if (expr.term.some((y) => match_principal_uids(y, x))) {
                                keep = true
                                break
                            }
                        }
                    } else {
                        assert_never(expr)
                    }
                    if (expr.not) {
                        keep = !keep
                    }
                    if (!keep) {
                        break
                    }
                }
                if (
                    (keep && keep_archived) ||
                    (keep &&
                        search_item.board_archived &&
                        !search_item.card_archived &&
                        board_matched_explicitely)
                ) {
                    result.push(search_item)
                }
            }
        }
        return result
    }

    private _search_item({
        board,
        source,
        board_archived,
        board_acl_all,
    }: {
        board: Board
        source: Card | Comment
        board_archived: boolean
        board_acl_all: Set<PrincipalUID>
    }): SearchItem {
        const texts: SearchItemTexts = {}
        let obj = source.note || source.link || (source.file as any)
        const card = source instanceof Card ? source : source.parent
        if (source instanceof Card && source.link) {
            const target_board_uid = extract_linked_board_and_card_uid(source.link.url)?.board_uid
            if (target_board_uid) {
                // Special handling for board-link-cards.
                obj = {
                    // Lazy evaluation of the title-attribute as board-names can change.
                    title: () => {
                        const board_info = this.board_info(target_board_uid)
                        return board_info ? escape_html(board_info.name) : undefined
                    },
                }
            }
        }
        if (obj) {
            for (const text_field of searchable_text_fields) {
                const v = obj[text_field]
                if (!v) {
                    continue
                }
                texts[text_field] =
                    text_field === "safe_html" || typeof v === "function" ? v : escape_html(v)
            }
        }
        let modified_at =
            source.last_important_change?.date.getTime() || source.last_change.date.getTime()
        if (source instanceof Card) {
            // This generic `modified_at` will take the comments into account.
            const last_comment = source.comments[source.comments.length - 1]
            if (last_comment) {
                modified_at = Math.max(
                    modified_at,
                    last_comment.last_important_change?.date.getTime() ||
                        last_comment.last_change.date.getTime(),
                )
            }
        }
        return {
            board_uid: board.uid,
            comment_uid: source instanceof Comment ? source.uid : undefined,
            card_uid: card.uid,
            texts,
            modified_at,
            board_archived,
            card_color: card.color,
            card_archived: card.archived,
            card_created_by: card.first_change.account_uid,
            card_modified_by: card.last_change.account_uid,
            card_kind: card.note ? "note" : card.link ? "link" : card.file ? "file" : undefined,
            task: !!card.task,
            task_status: card.task ? card.task.status : undefined,
            task_created_by: card.task ? card.task_first_change?.account_uid : undefined,
            task_modified_by: card.task ? card.task_last_change?.account_uid : undefined,
            task_assignee: card.task ? card.task.assignee : undefined,
            task_date: card.task?.date?.getTime(),
            board_owner: board.board_type === BoardType.people ? this.me_account_uid : board.owner,
            board_acl_all,
        }
    }
}
