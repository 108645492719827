import type {Board, BoardInfo} from "@cling/lib.shared.model"
import {i18n} from "@cling/lib.web.i18n"
import {Button, Icon, IconButton, Menu, MenuItem, MenuItemDivider} from "@cling/lib.web.mdc"
import {board_resource} from "@cling/lib.web.resources"
import {observer} from "mobx-react"
import * as React from "react"
import {open_intercom} from "../misc/intercom"
import {print_board} from "../misc/print"
import {ui_actions, ui_state} from "../state/index"
import {BoardTitle} from "./board_title"
import {ScrollIndicator} from "./scroll_indicator"
import {
    CollapseItem,
    SignUp,
    HolyTrinityOfVeryImportantLinks,
    PublicMenuLinkItem,
    TypographyLogo,
} from "./toolbar_items_common"

export const TopToolbarItemsDesktopPublicBoard = ({board_info}: {board_info?: BoardInfo}) => {
    const in_app = !!cling.public_board_in_app
    return (
        <nav
            className="toolbar-items toolbar-items--public toolbar-items--top"
            onMouseEnter={ui_actions.hide_card_menus}
            data-test-id="TopToolbarItems"
        >
            <TitleItem is_dashboard={false} board_info={board_info} />
            {!in_app && <SignUp raised secondary />}
            {in_app && <ReturnToApp />}
            <CollapseItem />
        </nav>
    )
}

export const BottomToolbarItemsDesktopPublicBoard = observer(() => {
    const in_app = !!cling.public_board_in_app
    return (
        <nav
            className="toolbar-items-public-bottom-desktop-right"
            onMouseEnter={ui_actions.hide_card_menus}
            data-test-id="BottomToolbarItems"
        >
            <TypographyLogo in_app={in_app} className="toolbar-items__typography-logo--small" />
            <HolyTrinityOfVeryImportantLinks />
        </nav>
    )
})

export const TopToolbarItemsMobilePublicBoard = ({
    is_dashboard,
    board_info,
}: {
    is_dashboard: boolean
    board_info?: BoardInfo
}) => {
    const in_app = !!cling.public_board_in_app
    return (
        <nav
            className="toolbar-items toolbar-items--public toolbar-items--top"
            data-test-id="TopToolbarItems"
        >
            <TitleItem is_dashboard={is_dashboard} board_info={board_info} />
            <PublicBoardMenuItem
                board={board_info && board_resource.read(board_info.uid)}
                in_app={in_app}
            />
        </nav>
    )
}

export const BottomToolbarItemsMobilePublicBoard = observer(
    (props: {is_dashboard: boolean; board?: Board}) => {
        const in_app = !!cling.public_board_in_app
        const show_scroll_indicator = !ui_state.layout_state.all_columns_fully_visible
        return (
            <>
                {show_scroll_indicator && <ScrollIndicator board={props.board} />}
                <nav
                    className="toolbar-items toolbar-items--public toolbar-items--bottom"
                    data-test-id="BottomToolbarItems"
                >
                    <TypographyLogo in_app={in_app} />
                    <div className="toolbar-items__flex" />
                    {!in_app && <SignUp raised secondary />}
                    {in_app && <ReturnToApp />}
                </nav>
            </>
        )
    },
)

const ReturnToApp = () => {
    const close = React.useCallback(() => window.close(), [])
    return (
        <Button
            className="toolbar-items__cta"
            raised
            secondary
            onClick={close}
            data-test-id="CloseWindow"
        >
            {i18n.return_to_app}
        </Button>
    )
}

const TitleItem = ({is_dashboard, board_info}: {is_dashboard: boolean; board_info?: BoardInfo}) => (
    <BoardTitle
        board={board_info}
        title_override={is_dashboard ? "Cling" : undefined}
        className="mdc-top-app-bar__title toolbar-items__title toolbar-items__title--left"
        data-test-id="ToolbarItems_title"
    />
)

const PublicBoardMenuItem = observer(({board, in_app}: {board?: Board; in_app: boolean}) => {
    const print = React.useCallback(() => {
        if (!board) {
            return
        }
        print_board(board)
    }, [board])
    const open_help = React.useCallback(() => open_intercom("home"), [])
    const toggle_collapse_expand_all = React.useCallback(() => {
        ui_actions.toggle_collapse_expand_all()
    }, [])
    return (
        <Menu
            className="toolbar-items__board-menu"
            data-test-id="PublicBoardMenu"
            anchor={
                <IconButton
                    icon="more_vert"
                    data-test-id="ToolbarItems_board_menu"
                    outlined
                    tooltip={i18n.more}
                />
            }
        >
            {!!board && (
                <MenuItem
                    graphic={
                        <Icon
                            icon={
                                ui_state.toggle_collapse_expand_all ? "view_stream" : "view_module"
                            }
                        />
                    }
                    onClick={toggle_collapse_expand_all}
                    title={i18n.toggle_collapse_expand_all(ui_state.toggle_collapse_expand_all)}
                />
            )}
            {!!board && (
                <MenuItem
                    onClick={print}
                    title={i18n.print}
                    graphic={<Icon icon="print" outlined />}
                    data-test-id="ToolbarItems_print"
                />
            )}
            {!!board && <MenuItemDivider />}
            {!in_app && (
                <PublicMenuLinkItem icon="login" title={i18n.log_in} uri="login.html" button />
            )}
            <MenuItem
                onClick={open_help}
                title={i18n.get_help}
                graphic={<Icon icon="chat_bubble_outline" outlined />}
            />
            {!in_app && (
                <>
                    <MenuItemDivider />
                    <PublicMenuLinkItem
                        icon="collections"
                        title={i18n.how_it_works}
                        uri="detail/index.html"
                    />
                    <PublicMenuLinkItem icon="group" title={i18n.for_teams} uri="teams.html" />
                    <PublicMenuLinkItem
                        icon="format_list_bulleted"
                        title={i18n.pricing}
                        uri="pricing.html"
                    />
                    <MenuItemDivider />
                    <PublicMenuLinkItem icon="subject" title={i18n.terms_of_use} uri="terms.html" />
                    <PublicMenuLinkItem
                        icon="privacy_tip"
                        title={i18n.privacy}
                        uri="privacy.html"
                    />
                    <PublicMenuLinkItem icon="info" title={i18n.imprint} uri="about.html" />
                </>
            )}
        </Menu>
    )
})
