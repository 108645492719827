import * as React from "react"
import {classNames} from "@cling/lib.web.utils"
import {i18n} from "@cling/lib.web.i18n"

export function HighlightBadge({
    onClick,
    margin_left,
    type,
    overlay,
    className,
}: {
    onClick?: () => void
    margin_left?: boolean
    type: "pro" | "new"
    overlay?: boolean
    className?: string
}) {
    return (
        <div
            className={classNames("highlight-badge", className, {
                "highlight-badge--margin-left": margin_left,
                "highlight-badge--pro": type === "pro",
                "highlight-badge--new": type === "new",
                "highlight-badge--overlay": overlay,
            })}
            onClick={onClick}
        >
            {type === "pro" && "PRO"}
            {type === "new" && i18n.new_.toUpperCase()}
        </div>
    )
}
