import {report_error} from "@cling/lib.shared.debug"
import {
    AccessLevel,
    Board,
    BoardInfo,
    BoardType,
    BoardUID,
    board_uid_type,
    derive_clipboard_uid,
    derive_trashcan_uid,
    FeatureSwitch,
} from "@cling/lib.shared.model"
import {BoardChooserMenu} from "@cling/lib.web.board_chooser"
import {i18n} from "@cling/lib.web.i18n"
import {React_lazy, React_suspense} from "@cling/lib.web.lazy_load/suspense"
import {Button, Icon, IconButton, Menu, MenuItem, MenuItemDivider} from "@cling/lib.web.mdc"
import {BoardPermissions, board_resource, meet_status_resource} from "@cling/lib.web.resources"
import {cancel_event, running_on_mobile_device} from "@cling/lib.web.utils"
import {navigate} from "@reach/router/index"
import {runInAction} from "mobx"
import {observer} from "mobx-react"
import * as React from "react"
import {useCallback} from "react"
import {HighlightBadge} from "../account/highlight_badge"
import {meet_state} from "../meet/meet_state"
import {GlobalBackdrop} from "../misc/global_backdrop"
import {open_intercom} from "../misc/intercom"
import {print_board} from "../misc/print"
import {current_user, export_board_state, ui_actions, ui_state} from "../state/index"
import model_actions from "../state/model_actions"
import {goto_board, goto_dashboard} from "../utils"
import {
    board_chooser_board_infos,
    board_chooser_props,
    people_chooser_board_infos,
    people_chooser_props,
} from "./board_chooser_props"
import {board_history_state} from "./board_history"
import {BoardTitle} from "./board_title"
import {ScrollIndicator} from "./scroll_indicator"
import {ToggleSortBoardsAlphabetically} from "./toggle_sort_boards_alphabetically"
const SearchBox = React_lazy(
    () => import(process.env.F_PUBLIC_LAZY || "@cling/client.web_app/search/search_box"),
    "eager_load",
)

export const TopToolbarItemsDesktopBoard = observer(
    ({
        open_drawer,
        permissions,
        is_dashboard,
        board_info,
    }: {
        is_dashboard: boolean
        board_info?: BoardInfo
        permissions: BoardPermissions
        open_drawer: () => void
    }) => {
        const {search_box_open} = ui_state.search_state
        return (
            <nav
                className="toolbar-items toolbar-items--top"
                onMouseEnter={ui_actions.hide_card_menus}
            >
                <div className="toolbar-items__top-left">
                    {is_dashboard && <MenuDrawerItem open_drawer={open_drawer} />}
                    {!is_dashboard && <GoToHomeItem icon="home" is_dashboard={is_dashboard} />}
                    <ToggleDesktopBoardChooserItem board_info={board_info} />
                    <ToggleMainViewItem />
                    {!search_box_open && <ClosedSearchItem />}
                    {search_box_open && (
                        <div className="toolbar-items__search-open-placeholder--desktop" />
                    )}
                </div>
                {search_box_open && <OpenSearchItem />}
                {!search_box_open && (
                    <TitleItem
                        is_dashboard={is_dashboard}
                        board_info={board_info}
                        permissions={permissions}
                    />
                )}
                <div className="toolbar-items__top-right-container">
                    <div className="toolbar-items__top-right">
                        {permissions.can_join_video_meeting &&
                            board_info &&
                            !ui_state.search_state.all_boards && (
                                <VideoMeetingButton board_info={board_info} />
                            )}
                        {permissions.can_share_board && <ShareItem permissions={permissions} />}
                        {ui_state.main_view !== "calendar" && !ui_state.search_state.all_boards && (
                            <CollapseItem />
                        )}
                        <BoardMenuItem permissions={permissions} board_info={board_info} />
                    </div>
                </div>
            </nav>
        )
    },
)

export const TopToolbarItemsMobileBoard = observer(
    ({
        board_info,
        permissions,
        is_dashboard,
        open_drawer,
    }: {
        is_dashboard: boolean
        board_info?: BoardInfo
        permissions: BoardPermissions
        open_drawer: () => void
    }) => {
        const {search_box_open} = ui_state.search_state
        if (search_box_open) {
            return (
                <nav className="toolbar-items toolbar-items--top toolbar-items--search-open">
                    <OpenSearchItem />
                </nav>
            )
        }
        return (
            <nav className="toolbar-items toolbar-items--top">
                {is_dashboard && <MenuDrawerItem open_drawer={open_drawer} />}
                {!is_dashboard && <GoToHomeItem icon="arrow_back" is_dashboard={is_dashboard} />}
                <TitleItem
                    is_dashboard={is_dashboard}
                    permissions={permissions}
                    board_info={board_info}
                />
                {permissions.can_join_video_meeting && board_info && (
                    <VideoMeetingButton board_info={board_info} />
                )}
                {ui_state.main_view !== "calendar" && !ui_state.search_state.all_boards && (
                    <CollapseItem />
                )}
                <BoardMenuItem board_info={board_info} permissions={permissions} />
            </nav>
        )
    },
)

export const BottomToolbarItemsMobileBoard = ({
    board,
    is_dashboard,
}: {
    is_dashboard: boolean
    board?: Board
}) => {
    return (
        <>
            {ui_state.main_view === "columns" && <ScrollIndicator board={board} />}
            <nav className="toolbar-items toolbar-items--bottom">
                <GlobalBackdrop />
                <GoToHomeItem icon="home" is_dashboard={is_dashboard} />
                <PeopleChooserItem />
                <BoardChooserItem />
                <ToggleMainViewItem />
                <ClosedSearchItem />
            </nav>
        </>
    )
}

const MenuDrawerItem = observer(({open_drawer}: {open_drawer: () => void}) => {
    return (
        <IconButton
            data-test-id="ToolbarItems_open_drawer"
            icon="menu"
            onClick={open_drawer}
            small={!running_on_mobile_device()}
            tooltip={running_on_mobile_device() ? undefined : i18n.menu}
        />
    )
})

export const TitleItem = (props: {
    is_dashboard: boolean
    board_info?: BoardInfo
    permissions: {
        can_change_board_settings: boolean
    }
}) => {
    const {is_dashboard, board_info, permissions} = props
    return (
        <BoardTitle
            board={board_info}
            title_override={is_dashboard ? "Cling" : undefined}
            className="mdc-top-app-bar__title toolbar-items__title"
            onClick={
                permissions.can_change_board_settings && !running_on_mobile_device()
                    ? ui_actions.open_board_settings_dialog
                    : undefined
            }
            data-test-id="ToolbarItems_title"
        />
    )
}

const ToggleDesktopBoardChooserItem = observer(
    ({board_info}: {board_info: BoardInfo | undefined}) => {
        const toggle = useCallback(
            () =>
                ui_actions.toggle_desktop_board_chooser(
                    ui_state.desktop_board_chooser_state === "hidden"
                        ? board_info?.board_type === BoardType.people
                            ? "people"
                            : "boards"
                        : "hidden",
                ),
            [board_info],
        )
        const show_badge =
            people_chooser_board_infos().some((x) => ui_state.is_new_or_changed(x)) ||
            board_chooser_board_infos("dashboard_and_user_boards", AccessLevel.read).some(
                (x) => x.is_user_board && ui_state.is_new_or_changed(x),
            )
        return (
            <IconButton
                icon="dashboard"
                outlined={ui_state.desktop_board_chooser_state === "hidden"}
                tooltip={i18n.boards}
                badge={show_badge ? {secondary: true, value: "", small: true} : undefined}
                small={!running_on_mobile_device()}
                onClick={toggle}
            />
        )
    },
)

export const BoardChooserItem = observer(() => {
    const [is_menu_open, set_is_menu_open] = React.useState(false)
    const open_menu = React.useCallback(() => {
        set_is_menu_open(true)
    }, [])
    const close_menu = React.useCallback(() => {
        set_is_menu_open(false)
    }, [])
    const on_board_selected = React.useCallback((board_uid: BoardUID) => {
        const board_type = board_uid_type(board_uid)
        if (!running_on_mobile_device() && board_type === BoardType.clipboard) {
            ui_actions.toggle_clipboard_shown()
        } else if (!running_on_mobile_device() && board_type === BoardType.trashcan) {
            ui_actions.toggle_trashcan_shown()
        } else if (board_type === BoardType.dashboard) {
            goto_dashboard().catch(report_error)
        } else {
            goto_board({board_uid}).catch(report_error)
        }
    }, [])
    const add_board = React.useCallback(() => {
        set_is_menu_open(false)
        ui_actions.open_add_board_dialog()
    }, [])
    const is_regular_board = ui_state.current_board.board?.board_type === BoardType.regular
    return (
        <BoardChooserMenu
            data-test-id="ToolbarItems_board_chooser_menu"
            is_open={is_menu_open}
            onOpen={open_menu}
            onClose={close_menu}
            anchor={
                <IconButton
                    icon="dashboard"
                    tooltip={i18n.boards}
                    outlined={!is_regular_board}
                    data-test-id={"BoardChooserItem"}
                    small={!running_on_mobile_device()}
                    label={i18n.boards}
                />
            }
            {...board_chooser_props("user_boards")}
            sort_boards_alphabetically={current_user.account_settings.sort_boards_alphabetically}
            onSelected={on_board_selected}
            first_menu_item={
                <div key="add_board">
                    <TryClingPro />
                    <div className="mt--1 flex-row justify-content--center">
                        <Button
                            raised
                            onClick={add_board}
                            data-test-id="BoardChooserItem_add_board"
                        >
                            {i18n.add_a_board}
                        </Button>
                    </div>
                    <div className="mt--1 flex-row justify-content--center">
                        <ToggleSortBoardsAlphabetically display="link" />
                    </div>
                </div>
            }
        />
    )
})

export const PeopleChooserItem = observer(() => {
    const [is_menu_open, set_is_menu_open] = React.useState(false)
    const open_menu = React.useCallback(() => {
        set_is_menu_open(true)
    }, [])
    const close_menu = React.useCallback(() => {
        set_is_menu_open(false)
    }, [])
    const on_board_selected = React.useCallback((board_uid: BoardUID) => {
        const board_type = board_uid_type(board_uid)
        if (board_type === BoardType.dashboard) {
            goto_dashboard().catch(report_error)
        } else {
            goto_board({board_uid}).catch(report_error)
        }
    }, [])
    const add_board = React.useCallback(() => {
        set_is_menu_open(false)
        ui_actions.open_start_conversation_dialog()
    }, [])
    const is_people_board = ui_state.current_board.board?.board_type === BoardType.people
    return (
        <BoardChooserMenu
            is_open={is_menu_open}
            onOpen={open_menu}
            onClose={close_menu}
            anchor={
                <IconButton
                    icon="face"
                    tooltip={i18n.people}
                    outlined={!is_people_board}
                    data-test-id={"PeopleChooserItem"}
                    small={!running_on_mobile_device()}
                    label={i18n.people}
                />
            }
            {...people_chooser_props()}
            onSelected={on_board_selected}
            sort_boards_alphabetically={current_user.account_settings.sort_boards_alphabetically}
            first_menu_item={
                <div key="add_board">
                    <TryClingPro />
                    <div className="mt--1 flex-row justify-content--center">
                        <Button raised onClick={add_board}>
                            {i18n.start_a_conversation}
                        </Button>
                    </div>
                    <div className="mt--1 flex-row justify-content--center">
                        <ToggleSortBoardsAlphabetically display="link" />
                    </div>
                </div>
            }
        />
    )
})

const CollapseItem = observer(() => {
    const toggle_collapse_expand_all = React.useCallback(() => {
        ui_actions.toggle_collapse_expand_all()
    }, [])
    return (
        <IconButton
            icon={ui_state.toggle_collapse_expand_all ? "view_stream" : "view_module"}
            data-test-id="ToolbarItems_collapse_expand_all"
            tooltip={i18n.toggle_collapse_expand_all(ui_state.toggle_collapse_expand_all)}
            small={!running_on_mobile_device()}
            onClick={toggle_collapse_expand_all}
        />
    )
})

const ToggleMainViewItem = observer(() => {
    const is_columns = ui_state.main_view === "columns"
    return (
        <div>
            <IconButton
                icon={is_columns ? "today" : "view_week"}
                outlined
                data-test-id="ToggleMainViewItem"
                tooltip={is_columns ? i18n.calendar : i18n.columns}
                small={!running_on_mobile_device()}
                onClick={ui_actions.toggle_calendar}
                label={
                    running_on_mobile_device()
                        ? is_columns
                            ? i18n.calendar
                            : i18n.columns
                        : undefined
                }
            />
        </div>
    )
})

const ShareItem = observer((props: {permissions: {can_share_board: boolean}}) => {
    const {permissions} = props
    const disabled = !permissions.can_share_board || ui_state.search_state.all_boards
    return (
        <IconButton
            disabled={disabled}
            icon="share"
            tooltip={i18n.share_this_board}
            small={!running_on_mobile_device()}
            data-test-id="ShareItem"
            onClick={disabled ? undefined : ui_actions.open_share_board_dialog}
        />
    )
})

const VideoMeetingButton = observer(({board_info}: {board_info: BoardInfo}) => {
    const on_click = React.useCallback(() => {
        runInAction(() => {
            if (!board_info) {
                return
            }
            if (!meet_state.board?.uid) {
                meet_state.join(board_info)
            }
        })
    }, [board_info])
    const num_participants = meet_status_resource.read(board_info.uid)?.participants.length ?? 0
    return (
        <IconButton
            onClick={on_click}
            icon="voice_chat"
            outlined={meet_state.board?.uid !== board_info.uid}
            small={!running_on_mobile_device()}
            tooltip={i18n.join_video_meeting}
            badge={
                num_participants > 0
                    ? {
                          secondary: true,
                          value: num_participants,
                          small: false,
                      }
                    : undefined
            }
            data-test-id="Toolbar_meet"
        />
    )
})

const ClosedSearchItem = () => {
    const open_search_box = React.useCallback((e) => {
        // It is important to cancel the event because it might bubble up and get caught by
        // the click-handler registered at document level in `search_box.tsx`.
        cancel_event(e)
        ui_state.search_state.open_search_box()
    }, [])
    return (
        <IconButton
            icon="search"
            tooltip={`${i18n.search}   /`}
            onClick={open_search_box}
            small={!running_on_mobile_device()}
            label={running_on_mobile_device() ? i18n.search : undefined}
            data-test-id="ToolbarItems_open_search"
        />
    )
}

export const OpenSearchItem = () => {
    const search = React.useCallback((new_query_string: string) => {
        ui_state.search_state
            .search(new_query_string)
            .then(() => {
                runInAction(() => {
                    if (new_query_string && ui_state.main_view === "columns") {
                        ui_state.layout_state.scroll_to_column(0)
                        ui_state.layout_state.scroll_all_columns_to_top()
                    }
                    if (ui_state.search_state.is_search_narrowing_down) {
                        ui_state.highlighted_card_state = undefined
                    }
                })
            })
            .catch(report_error)
    }, [])
    const end_search = React.useCallback(() => {
        ui_state.search_state.end_search()
        if (ui_state.main_view === "columns") {
            ui_state.layout_state.scroll_all_columns_to_top()
            ui_state.layout_state.scroll_to_natural_first_column()
        }
    }, [])
    React.useEffect(() => {
        ui_actions.fully_expand_all()
    }, [])
    return (
        <div className="toolbar-items__search">
            <React_suspense>
                <SearchBox initial_query_string={""} onChange={search} onClose={end_search} />
            </React_suspense>
        </div>
    )
}

export const GoToHomeItem = ({
    icon,
    is_dashboard,
}: {
    is_dashboard: boolean
    icon: "home" | "arrow_back"
}) => {
    const on_click = React.useCallback(() => {
        if (ui_state.main_view === "calendar") {
            ui_actions.toggle_calendar()
        }
        if (is_dashboard) {
            ui_state.layout_state.scroll_all_columns_to_top()
            ui_state.layout_state.scroll_to_natural_first_column()
        } else {
            goto_dashboard().catch(report_error)
        }
    }, [is_dashboard])
    return (
        <IconButton
            icon={icon}
            outlined={!is_dashboard}
            onClick={on_click}
            small={!running_on_mobile_device()}
            data-test-id="GoToHomeItem"
            label={
                running_on_mobile_device()
                    ? icon === "arrow_back"
                        ? undefined
                        : i18n.home
                    : undefined
            }
            tooltip={running_on_mobile_device() ? undefined : i18n.home}
        />
    )
}

export const BoardMenuItem = observer(
    ({board_info, permissions}: {board_info?: BoardInfo; permissions: BoardPermissions}) => {
        const menu_ref = React.useRef<Menu>(null)
        const export_board = React.useCallback(async () => {
            if (!board_info || export_board_state.export_pending) {
                return
            }
            export_board_state.start_export(board_info.uid).catch(report_error)
        }, [board_info])
        const show_export_board_teaser = React.useCallback(() => {
            ui_actions.open_pro_feature_teaser_dialog({
                description: i18n.export_board_lets_you_download,
            })
        }, [])
        const empty_trashcan = React.useCallback(() => {
            model_actions.empty_trashcan()
        }, [])
        const toggle_archived_cards = React.useCallback(() => {
            if (ui_state.search_state.archived_cards_shown) {
                ui_actions.show_archived(false).catch(report_error)
            } else {
                ui_actions.show_archived(true).catch(report_error)
            }
        }, [])
        const open_trashcan = React.useCallback(() => {
            if (running_on_mobile_device()) {
                goto_board({board_uid: derive_trashcan_uid(current_user.account.uid)}).catch(
                    report_error,
                )
            } else {
                ui_actions.toggle_trashcan_shown()
            }
        }, [])
        const open_clipboard = React.useCallback(() => {
            if (running_on_mobile_device()) {
                goto_board({board_uid: derive_clipboard_uid(current_user.account.uid)}).catch(
                    report_error,
                )
            } else {
                ui_actions.toggle_clipboard_shown()
            }
        }, [])
        const print = React.useCallback(() => {
            if (!board_info) {
                return
            }
            const board = board_resource.read(board_info.uid)
            if (!board) {
                return
            }
            print_board(board)
        }, [board_info])
        const email_markup = React.useCallback(() => {
            if (!board_info) {
                return
            }
            navigate(`/c/email_markup/${board_info.uid}`).catch(report_error)
        }, [board_info])
        const archive_board = React.useCallback(() => {
            if (!board_info) {
                return
            }
            model_actions.archive_board(board_info).catch(report_error)
        }, [board_info])
        const unarchive_board = React.useCallback(() => {
            if (!board_info) {
                return
            }
            model_actions.unarchive_board(board_info).catch(report_error)
        }, [board_info])
        const remove_board = React.useCallback(() => {
            if (!board_info) {
                return
            }
            model_actions.remove_board(board_info)
        }, [board_info])
        const leave_board = React.useCallback(() => {
            if (!board_info) {
                return
            }
            model_actions.remove_acl_entry(board_info, current_user.account.uid)
        }, [board_info])
        const open_help = React.useCallback(() => open_intercom("home"), [])
        const is_board_archived = !!(
            board_info && current_user.account_settings.board_setting(board_info.uid)?.archived
        )
        const search_on_all_boards = ui_state.search_state.all_boards
        const show_share_board = permissions.can_share_board && running_on_mobile_device()
        const is_trashcan = board_info?.board_type === BoardType.trashcan
        const is_clipboard = board_info?.board_type === BoardType.clipboard
        const can_convert_to_email_markup =
            current_user.has_feature_switch(FeatureSwitch.email_markup) && board_info?.acl.is_public
        const can_view_history =
            permissions.can_view_history &&
            ui_state.main_view !== "calendar" &&
            !search_on_all_boards
        const can_open_clipboard_trashcan = ui_state.main_view !== "calendar"
        const can_print = ui_state.main_view !== "calendar" && !search_on_all_boards
        const can_copy_board = permissions.can_copy_board && !search_on_all_boards
        const can_export_board = permissions.can_export_board && !search_on_all_boards
        const could_export_board_in_cling_pro =
            permissions.could_export_board_in_cling_pro && !search_on_all_boards
        const can_open_board_settings =
            !search_on_all_boards && permissions.can_change_board_settings
        const can_import_data =
            permissions.can_import_data && !search_on_all_boards && ui_state.main_view === "columns"
        const can_show_archived_cards =
            !ui_state.search_state.search_box_open && !search_on_all_boards && board_info
        const can_archive_board = permissions.can_archive_board && !search_on_all_boards
        const can_remove_board = permissions.can_remove_board && !search_on_all_boards
        const can_leave_board = permissions.can_leave_board && !search_on_all_boards
        return (
            <Menu
                className="toolbar-items__board-menu"
                data-test-id="BoardMenu"
                ref={menu_ref}
                anchor={
                    <IconButton
                        icon="more_vert"
                        data-test-id="ToolbarItems_board_menu"
                        outlined
                        small={!running_on_mobile_device()}
                        tooltip={i18n.more}
                    />
                }
            >
                {can_show_archived_cards && (
                    <MenuItem
                        onClick={toggle_archived_cards}
                        title={
                            ui_state.search_state.archived_cards_shown
                                ? i18n.hide_archived_cards
                                : i18n.show_archived_cards
                        }
                        graphic={
                            <Icon
                                icon="archive"
                                outlined={!ui_state.search_state.archived_cards_shown}
                            />
                        }
                    />
                )}
                {can_open_board_settings && (
                    <MenuItem
                        onClick={ui_actions.open_board_settings_dialog}
                        title={i18n.board_settings}
                        graphic={<Icon icon="settings" outlined />}
                    />
                )}
                {can_import_data && (
                    <MenuItem
                        onClick={ui_actions.open_import_data_dialog}
                        title={i18n.import_data}
                        graphic={<Icon icon="input" outlined />}
                        data-test-id="ToolbarItems_import_data"
                    />
                )}
                {can_print && (
                    <MenuItem
                        onClick={print}
                        title={i18n.print}
                        graphic={<Icon icon="print" outlined />}
                        data-test-id="ToolbarItems_print"
                    />
                )}
                {can_convert_to_email_markup && (
                    <MenuItem
                        onClick={email_markup}
                        title={i18n.email_markup}
                        graphic={<Icon icon="email" outlined />}
                    />
                )}
                {(can_show_archived_cards ||
                    can_open_board_settings ||
                    can_import_data ||
                    can_print ||
                    can_convert_to_email_markup) && <MenuItemDivider />}
                <MenuItem
                    onClick={open_help}
                    title={i18n.get_help}
                    graphic={<Icon icon="chat_bubble_outline" outlined />}
                />
                {is_trashcan && <MenuItemDivider />}
                {is_trashcan && (
                    <MenuItem
                        title={i18n.empty_trashcan}
                        graphic={<Icon icon="delete_sweep" outlined />}
                        onClick={empty_trashcan}
                        data-test-id="BoardMenu_empty_trashcan"
                    />
                )}
                {(can_export_board ||
                    could_export_board_in_cling_pro ||
                    can_archive_board ||
                    can_remove_board ||
                    show_share_board ||
                    can_view_history ||
                    can_leave_board) && <MenuItemDivider />}
                {can_view_history && (
                    <MenuItem
                        onClick={board_history_state.toggle}
                        title={i18n.version_history}
                        data-test-id="BoardMenu_version_history"
                        graphic={<Icon icon="history" outlined />}
                    />
                )}
                {show_share_board && (
                    <MenuItem
                        graphic={<Icon icon="share" outlined />}
                        title={i18n.share_board}
                        data-test-id="BoardMenu_share_board"
                        onClick={ui_actions.open_share_board_dialog}
                    />
                )}
                {can_copy_board && (
                    <MenuItem
                        onClick={ui_actions.open_copy_board_dialog}
                        data-test-id="BoardMenu_copy_board"
                        graphic={<Icon icon="content_copy" outlined />}
                        title={i18n.copy_board}
                    />
                )}
                {(can_export_board || could_export_board_in_cling_pro) && board_info && (
                    <MenuItem
                        onClick={can_export_board ? export_board : show_export_board_teaser}
                        disabled={export_board_state.export_pending}
                        data-test-id="ToolbarItems_export_board"
                        graphic={<Icon icon="cloud_download" outlined />}
                        title={i18n.export_board}
                        meta={!can_export_board && <HighlightBadge type="pro" />}
                    />
                )}
                {can_archive_board && board_info && (
                    <MenuItem
                        title={is_board_archived ? i18n.restore_board : i18n.archive_board}
                        graphic={
                            <Icon icon={is_board_archived ? "unarchive" : "archive"} outlined />
                        }
                        onClick={is_board_archived ? unarchive_board : archive_board}
                    />
                )}
                {can_remove_board && board_info && (
                    <MenuItem
                        className="toolbar-items__dangerous"
                        title={i18n.remove_board}
                        graphic={<Icon icon="delete" outlined />}
                        onClick={remove_board}
                    />
                )}
                {can_leave_board && board_info && (
                    <MenuItem
                        className="toolbar-items__dangerous"
                        title={i18n.leave_board}
                        graphic={<Icon icon="person_remove" outlined />}
                        onClick={leave_board}
                    />
                )}
                {can_open_clipboard_trashcan && (
                    <>
                        <MenuItemDivider />
                        <MenuItem
                            title={i18n.open_clipboard}
                            disabled={is_clipboard}
                            graphic={
                                <Icon icon="content_paste" outlined={!ui_state.clipboard_shown} />
                            }
                            onClick={open_clipboard}
                            data-test-id="BoardMenu_open_clipboard"
                        />
                        <MenuItem
                            title={i18n.open_trashcan}
                            disabled={is_trashcan}
                            graphic={<Icon icon="delete" outlined={!ui_state.trashcan_shown} />}
                            onClick={open_trashcan}
                            data-test-id="BoardMenu_open_trashcan"
                        />
                    </>
                )}
            </Menu>
        )
    },
)

const TryClingPro = () => {
    if (
        current_user.account_attributes.has_pro_features ||
        // Needed for Obersee.
        current_user.account_attributes.team_uids.length > 0
    ) {
        return null
    }
    return (
        <div className="toolbar-items__try-cling-pro full-width flex-col align-items--center">
            <Button raised secondary onClick={ui_actions.open_checkout_dialog}>
                {i18n.try_cling_pro}
            </Button>
            <div className="font--caption text-align--center mt--1">{i18n.get_all_the_space}</div>
        </div>
    )
}
