import {observable, makeObservable, runInAction} from "mobx"
import {from_buffer} from "@cling/lib.shared.model"
import {NotFound} from "@cling/lib.shared.error/errors"
import type {UID} from "@cling/lib.shared.types/common"

/**
 * A local cache that does not deal with versions of entities. It simply stores everything it
 * is told to store. The responsibility for feeding correct versions of entities lies outside
 * this cache.
 * This highly reduces complexity and we have a single source of truth.
 */
export interface LocalCache<K extends UID, V extends {_to_pb: (args: any) => any}> {
    /**
     * An error is thrown if the key does not exist.
     * Some data might still arrive later. In those cases you have to check with `has()` before.
     */
    get(key: K): V
    has(key: K): boolean
    put(key: K, pb: Uint8Array, o?: V): void
    remove(key: K): void
    values(): Iterable<V>
    keys(): Iterable<K>
}

interface ModelClass<V> {
    name: string
    _from_pb: (p: any) => V
}

export function default_local_cache<K extends UID, V extends {_to_pb: (args: any) => any}>(
    type: ModelClass<V>,
): LocalCache<K, V> {
    return new LocalCacheImpl({type})
}

export class LocalCacheImpl<K extends UID, V extends {_to_pb: (args: any) => any}>
    implements LocalCache<K, V>
{
    private map = new Map<K, {pb?: Uint8Array; o?: V}>()
    private type: ModelClass<V>

    constructor({type}: {type: ModelClass<V>}) {
        makeObservable<LocalCacheImpl<K, V>, "map">(this, {
            map: observable.shallow,
        })
        this.type = type
    }

    get(key: K): V {
        const cache_entry = this.map.get(key)
        if (!cache_entry) {
            throw new NotFound(`Entity (${this.type.name}) is not cached`, key)
        }
        if (!cache_entry.o) {
            cache_entry.o = from_buffer(this.type, cache_entry.pb!)
            cache_entry.pb = undefined
        }
        return cache_entry.o
    }

    has(key: K): boolean {
        return this.map.has(key)
    }

    put(key: K, pb: Uint8Array, o?: V) {
        runInAction(() => this.map.set(key, {pb, o}))
    }

    remove(key: K) {
        runInAction(() => this.map.delete(key))
    }

    *values(): Iterable<V> {
        for (const key of this.keys()) {
            yield this.get(key)
        }
    }

    keys(): Iterable<K> {
        return this.map.keys()
    }
}
