import * as React from "react"
import {observer} from "mobx-react"
import {Page, TopAppBar} from "@cling/lib.web.mdc"
import {board_uid_type, BoardInfo, BoardStyleStruct, BoardType} from "@cling/lib.shared.model"
import {BoardContainer} from "../board/board_container"
import {BoardBackground} from "../board/board_background"
import {
    BottomDesktopToolbars,
    BottomMobileToolbarItems,
    TopToolbarItems,
} from "../board/toolbar_items"
import {board_info_resource} from "@cling/lib.web.resources"
import {current_user, ui_actions, ui_state} from "../state/index"
import {running_on_mobile_device} from "@cling/lib.web.utils"
import {BoardDrawer} from "../board/board_drawer"
import {background_style} from "@cling/lib.web.layout"
import {GlobalBackdrop} from "../misc/global_backdrop"
import {profiler} from "../profiler"
import {BottomAppBar} from "@cling/lib.web.mdc/bottom_app_bar"
import {i18n} from "@cling/lib.web.i18n"
import {classNames} from "@cling/lib.web.utils"
import {CSSTransition} from "react-transition-group"
import {board_name} from "../board/board_name"
import {DesktopBoardChooser} from "../board/desktop_board_chooser"
import {BoardContext} from "../board_context"
import {React_lazy, React_suspense} from "@cling/lib.web.lazy_load/suspense"
import {board_background_image_url} from "@cling/lib.web.resources/thumbnails"
const ObservingBoardHistory = React_lazy(
    () => import(process.env.F_PUBLIC_LAZY || "@cling/client.web_app/board/board_history"),
)

export const BoardPage = observer(() => {
    const [is_drawer_open, set_is_drawer_open] = React.useState(false)
    const open_drawer = React.useCallback(() => {
        ui_actions.hide_card_menus()
        set_is_drawer_open(true)
    }, [])
    React.useEffect(() => profiler.on_page_mounted(), [])
    const {
        current_board: {board, display_version},
        current_board_uid,
    } = React.useContext(BoardContext)
    const close_drawer = React.useCallback(() => {
        set_is_drawer_open(false)
    }, [])
    const on_drawer_open = React.useCallback(() => {
        set_is_drawer_open(true)
    }, [])
    if (!current_board_uid) {
        return null
    }
    let board_info: BoardInfo | undefined
    let board_style: BoardStyleStruct
    if (!board) {
        // If we have the `BoardInfo`, we can use its `board_style`.
        board_info = board_info_resource.read(current_board_uid)
        board_style = board_info?.board_style ?? {
            background_color: "",
            background_image_url: "",
            background_image_blob: undefined,
            background_is_pattern: false,
        }
    } else {
        board_style = board!.board_style
    }
    const permissions = board ? current_user.board_permissions(board, display_version) : ({} as any)
    const is_dashboard = board_uid_type(current_board_uid) === BoardType.dashboard
    let document_title = ui_state.search_state.all_boards
        ? i18n.search
        : is_dashboard
          ? Page.document_title_suffix
          : board_uid_type(current_board_uid) === BoardType.trashcan
            ? i18n.trashcan
            : board_uid_type(current_board_uid) === BoardType.clipboard
              ? i18n.clipboard
              : board
                ? board_name(board)
                : ""
    const {num_changed_user_boards} = ui_state
    if (num_changed_user_boards > 0) {
        document_title = `(${num_changed_user_boards}) ${document_title}`
    }
    // We use the `key` attribute to force a re-render once the currently used language
    // changes ...
    const top_app_bar = (
        <TopAppBar
            more_items={
                <TopToolbarItems
                    key={`toolbar_${current_user.account.locale}`}
                    board_info={board_info || board?.board_info}
                    is_dashboard={is_dashboard}
                    permissions={permissions}
                    open_drawer={open_drawer}
                />
            }
            className={classNames("board-page__top-app-bar", {
                "board-page__top-app-bar--shown": ui_state.app_bar_shown,
                "board-page__top-app-bar--hidden": !ui_state.app_bar_shown,
                "board-page__top-app-bar--search": ui_state.search_state.search_box_open,
                "board-page__top-app-bar--desktop-board-chooser-open":
                    ui_state.desktop_board_chooser_state !== "hidden",
            })}
        >
            <GlobalBackdrop />
        </TopAppBar>
    )
    const bottom_app_bar_shown =
        !running_on_mobile_device() ||
        (ui_state.app_bar_shown && !ui_state.search_state.search_box_open)
    const bottom_app_bar = running_on_mobile_device() ? (
        <BottomAppBar
            className={classNames("board-page__bottom-app-bar", {
                "board-page__bottom-app-bar--shown": bottom_app_bar_shown,
                "board-page__bottom-app-bar--hidden": !bottom_app_bar_shown,
            })}
        >
            <BottomMobileToolbarItems
                is_dashboard={is_dashboard}
                board_info={board_info || board?.board_info}
            />
        </BottomAppBar>
    ) : undefined
    const drawer = process.env.F_PUBLIC ? undefined : (
        <BoardDrawer
            is_open={is_drawer_open}
            close={close_drawer}
            onClose={close_drawer}
            onOpen={on_drawer_open}
            header_style={
                board
                    ? background_style({
                          url: board_background_image_url(board.board_style),
                          color: board.board_style.background_color,
                          is_pattern: board.board_style.background_is_pattern,
                      })
                    : undefined
            }
        />
    )
    const css_transition_enter_class = process.env.F_PUBLIC
        ? "board-page__content--enter-fast"
        : "board-page__content--enter"
    // We use the `key` attribute to `<Board .../>` to force a re-render once the board-uid,
    // the display mode (all boards vs single board), or the currently used language changes ...
    return (
        <Page
            document_title={document_title}
            top_app_bar={top_app_bar}
            bottom_app_bar={bottom_app_bar}
            drawer={drawer}
            data-test-id="BoardPage"
        >
            <BoardBackground
                key="background"
                board_uid={current_board_uid}
                board_style={board_style}
            />
            <div key="content" className="board-page__container">
                {!process.env.F_PUBLIC && !running_on_mobile_device() && <DesktopBoardChooser />}
                {board && (
                    <CSSTransition
                        key={
                            `${board.uid}_${display_version}_` +
                            `${ui_state.main_view}_` +
                            `${current_user.account.locale}`
                        }
                        classNames={{
                            appearActive: css_transition_enter_class,
                            appearDone: css_transition_enter_class,
                            enterActive: css_transition_enter_class,
                            enterDone: css_transition_enter_class,
                        }}
                        in
                        appear
                        timeout={10}
                    >
                        <div key="content" className="board-page__content">
                            <BoardContainer key="board" />
                        </div>
                    </CSSTransition>
                )}
                {!process.env.F_PUBLIC && (
                    <React_suspense>
                        <ObservingBoardHistory
                            key={`history:${board?.uid}:${current_user.account.locale}`}
                        />
                    </React_suspense>
                )}
                {!running_on_mobile_device() && <BottomDesktopToolbars />}
            </div>
        </Page>
    )
})
