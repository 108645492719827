import {
    AccountUID,
    SyncEntityType,
    BoardUID,
    Board,
    BoardInfo,
    Account,
    AccountSettings,
    AccountAttributes,
    AccountAuth,
    AccountAnalytics,
    TeamUID,
    Team,
    TeamMembers,
    URLUID,
    URLInfo,
    BlobUID,
    MediaInfo,
    ClingErrorCode,
    OrganizationUID,
    Organization,
    OrganizationMembers,
    MeetStatus,
} from "@cling/lib.shared.model"
import type {UID} from "@cling/lib.shared.types/common"
import {LocalCache, default_local_cache} from "@cling/lib.web.resources/local_cache"
import {local_eventbus} from "@cling/lib.web.primitives"
import {
    not_null,
    ControllablePromise,
    new_ControllablePromise,
    assert_never,
} from "@cling/lib.shared.utils"
import {log} from "@cling/lib.shared.logging"
import {NotFound, Gone, Forbidden, ClingError} from "@cling/lib.shared.error"
import type {SyncUpdate} from "@cling/lib.web.worker/worker_interface"
import {runInAction} from "mobx"

const outstanding = new Map<string, ControllablePromise<void> | undefined>()
let apply_batch_timeout: any
let ignore_sync_update: (msg: SyncUpdate) => boolean
let board_sync_paused: BoardUID | undefined = undefined
const batch: SyncUpdate[] = []

export async function init(ignore_sync_update_: typeof ignore_sync_update) {
    ignore_sync_update = ignore_sync_update_
    const new_cache = <K extends UID, V extends {_to_pb: (args: any) => any}>(
        type: any,
    ): LocalCache<K, V> => {
        return default_local_cache<K, V>(type) as any
    }
    const caches = {
        [SyncEntityType.board]: new_cache<BoardUID, Board>(Board),
        [SyncEntityType.board_info]: new_cache<BoardUID, BoardInfo>(BoardInfo),
        [SyncEntityType.account]: new_cache<AccountUID, Account>(Account),
        [SyncEntityType.account_settings]: new_cache<AccountUID, AccountSettings>(AccountSettings),
        [SyncEntityType.account_attributes]: new_cache<AccountUID, AccountAttributes>(
            AccountAttributes,
        ),
        [SyncEntityType.account_auth]: new_cache<AccountUID, AccountAuth>(AccountAuth),
        [SyncEntityType.account_analytics]: new_cache<AccountUID, AccountAnalytics>(
            AccountAnalytics,
        ),
        [SyncEntityType.organization]: new_cache<OrganizationUID, Organization>(Organization),
        [SyncEntityType.organization_members]: new_cache<OrganizationUID, OrganizationMembers>(
            OrganizationMembers,
        ),
        [SyncEntityType.team]: new_cache<TeamUID, Team>(Team),
        [SyncEntityType.team_members]: new_cache<TeamUID, TeamMembers>(TeamMembers),
        [SyncEntityType.url_info]: new_cache<URLUID, URLInfo>(URLInfo),
        [SyncEntityType.media_info]: new_cache<BlobUID, MediaInfo>(MediaInfo),
        [SyncEntityType.meet_status]: new_cache<BoardUID, MeetStatus>(MeetStatus),
    }
    const local_cache_sync_event_bus = local_eventbus<SyncUpdate>()
    local_cache_sync_event_bus.addEventListener(({data: msg}) => {
        // log.debug("Received sync message", {
        //     message_type: msg.type,
        //     uid: msg.uid,
        //     sync_entity_type: SyncEntityType[msg.sync_entity_type],
        // })
        // Debounce this message, so we can reduce the number of MobX triggers being fired.
        batch.push(msg)
        if (!apply_batch_timeout) {
            apply_batch_timeout = setTimeout(() => _apply_batch(caches), 97)
        }
    })
    return {
        caches,
        local_cache_sync_event_bus,
        pause_board_sync: (board_uid: BoardUID | undefined) => (board_sync_paused = board_uid),
    }
}

function _apply_batch(caches: Record<SyncEntityType, LocalCache<UID, any>>) {
    try {
        runInAction(() => {
            const retain = []
            try {
                while (batch.length > 0) {
                    const msg = batch.shift()!
                    if (msg.uid === board_sync_paused) {
                        retain.push(msg)
                        continue
                    }
                    _handle_message(msg, caches)
                }
            } finally {
                batch.push(...retain)
            }
        })
    } finally {
        apply_batch_timeout = 0
    }
    if (batch.length > 0) {
        clearTimeout(apply_batch_timeout)
        apply_batch_timeout = setTimeout(() => _apply_batch(caches), 597)
    }
}

function _handle_message(msg: SyncUpdate, caches: Record<SyncEntityType, LocalCache<UID, any>>) {
    const sync_key = outstanding_sync_key(msg.sync_entity_type, msg.uid)
    const promise = outstanding.get(sync_key)
    const cache = not_null(
        caches[msg.sync_entity_type],
        `No cache for ${SyncEntityType[msg.sync_entity_type]}`,
    )
    if (ignore_sync_update(msg)) {
        log.debug("Ignoring sync message", {
            message_type: msg.type,
            uid: msg.uid,
            sync_entity_type: SyncEntityType[msg.sync_entity_type],
        })
        return
    }
    log.debug("Handling sync message", {
        message_type: msg.type,
        uid: msg.uid,
        sync_entity_type: SyncEntityType[msg.sync_entity_type],
    })
    if (msg.type === "sync_put") {
        cache.put(msg.uid, msg.full_pb)
        promise?.resolve()
    } else if (msg.type === "sync_remove") {
        cache.remove(msg.uid)
        promise?.reject(new NotFound("Not found or lost access", msg.uid))
    } else if (msg.type === "sync_error") {
        cache.remove(msg.uid)
        if (msg.cling_error_code === ClingErrorCode.not_found) {
            promise?.reject(new NotFound("Not found or lost access", msg.uid))
        } else if (msg.cling_error_code === ClingErrorCode.gone) {
            promise?.reject(new Gone("Gone on server", msg.uid))
        } else if (msg.cling_error_code === ClingErrorCode.sec_forbidden) {
            promise?.reject(new Forbidden("Access denied or revoked", {uids: [msg.uid]}))
        } else {
            promise?.reject(
                new ClingError(`Unknown error: ${ClingErrorCode[msg.cling_error_code]}`, 400, {
                    extra: {cling_error_code: msg.cling_error_code},
                    uids: [msg.uid],
                }),
            )
        }
    } else if (msg.type === "sync_up_to_date") {
        promise?.resolve()
    } else {
        throw assert_never(msg)
    }
    outstanding.delete(sync_key)
}

export const request_sync_factory =
    ({request_sync}: {request_sync: (uid: UID, type: SyncEntityType) => void}) =>
    (uid: UID, type: SyncEntityType) => {
        const sync_key = outstanding_sync_key(type, uid)
        if (outstanding.has(sync_key)) {
            return
        }
        log.debug(`RequestSync ${uid}/${SyncEntityType[type]}`)
        outstanding.set(sync_key, undefined)
        request_sync(uid, type)
    }

export const wait_for_sync_factory =
    ({request_sync}: {request_sync: (uid: UID, type: SyncEntityType) => void}) =>
    async (uid: UID, type: SyncEntityType) => {
        const sync_key = outstanding_sync_key(type, uid)
        const cur_outstanding = outstanding.get(sync_key)
        if (cur_outstanding) {
            return cur_outstanding
        }
        log.debug(`RequestSync ${uid}/${SyncEntityType[type]}`)
        const promise = new_ControllablePromise<void>()
        outstanding.set(sync_key, promise)
        request_sync(uid, type)
        return promise
    }

function outstanding_sync_key(type: SyncEntityType, uid: UID) {
    return `${type}:${uid}`
}
