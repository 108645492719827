import * as React from "react"
import {classNames} from "@cling/lib.web.utils"
import {observer} from "mobx-react"
import {BoardContext} from "../board_context"
import {board_resource} from "@cling/lib.web.resources"
import {Button, Icon, IconButton, ListItem, SearchableList, Snackbar} from "@cling/lib.web.mdc"
import {
    AccountUID,
    Board,
    BoardChangelogEntry,
    BoardPatchOpType,
    BoardUID,
    Card,
    CardChangelogEntry,
    CardPatchOpType,
    CLING_BOT,
    CommentChangelogEntry,
    CommentPatchOpType,
} from "@cling/lib.shared.model"
import {observable, runInAction, makeObservable} from "mobx"
import {i18n} from "@cling/lib.web.i18n"
import {PrincipalInfo} from "../account/principal_info"
import {current_user, ui_actions, ui_state} from "../state/index"
import {RelDate} from "../misc/rel_date"
import {can_call_faas} from "@cling/lib.web.auth"
import {running_on_mobile_device, running_on_phone} from "@cling/lib.web.utils"
import {cancel_event} from "@cling/lib.web.utils"
import {not_null} from "@cling/lib.shared.utils"
import {useCardPermission, useCurrentBoardPermission} from "../state/permission_hooks"
import {HighlightBadge} from "../account/highlight_badge"
import {Chip, ChipSet} from "@cling/lib.web.mdc/chip"
import {CardSynopsisOneLiner} from "../card/card_synopsis"
import {LoadingIndicator} from "@cling/lib.web.lazy_load/loading_indicator"
import {GlobalBackdrop} from "../misc/global_backdrop"

class BoardHistoryState {
    private _open = false

    constructor() {
        makeObservable<BoardHistoryState, "_open">(this, {
            _open: observable,
        })
    }

    get is_open() {
        return this._open
    }

    open = () => {
        if (!ui_state.current_board.board || this._open) {
            return
        }
        if (!can_call_faas()) {
            Snackbar.show_message(i18n.version_history_is_not_available_are_you_offline)
        }
        runInAction(() => {
            this._open = true
        })
    }

    toggle = () => {
        if (this._open) {
            this.close()
        } else {
            this.open()
        }
    }

    close = () => {
        runInAction(() => {
            this._open = false
            ui_state.app_bar_enabled = true
        })
        ui_actions.highlight_card(undefined)
        if (!ui_state.current_board_uid) {
            return
        }
        ui_state.set_current_board_uid(ui_state.current_board_uid, "latest")
    }
}

export const board_history_state = new BoardHistoryState()

type ChangelogData = {
    account_uid: AccountUID
    patch_op_type: number
    board_uid?: BoardUID
    date: Date
}

const important_change_op_types = [
    CardPatchOpType.AddCard,
    CommentPatchOpType.AddComment,
    CardPatchOpType.SetCardFileBlob,
    CardPatchOpType.SetCardFileFileName,
    CardPatchOpType.SetCardFileTitle,
    CardPatchOpType.SetCardLinkTitle,
    CardPatchOpType.SetCardLinkURL,
    CardPatchOpType.SetCardNoteSafeHtml,
    CardPatchOpType.SetCardNoteTitle,
    CardPatchOpType.SetTaskAssignee,
    CardPatchOpType.SetTaskStatus,
    CardPatchOpType.SetTaskDate,
    CardPatchOpType.PasteCard,
] as number[]

export const BoardHistoryList = observer(
    ({
        search_visible,
        show_my_changes,
        show_important_only,
        onChange,
    }: {
        search_visible?: boolean
        show_my_changes: boolean
        show_important_only: boolean
        onChange?: () => void
    }) => {
        const list_ref = React.useRef<SearchableList>(null)
        const {
            current_board: {display_version},
            current_board_uid,
        } = React.useContext(BoardContext)
        const [changes, set_changes] = React.useState<
            {
                board_version: number
                card?: Card
                patch_op_type: CardPatchOpType | CommentPatchOpType | BoardPatchOpType
                account_uid: AccountUID
                date: Date
                desc: React.ReactElement
            }[]
        >([])
        const [show_spinner_for, set_show_spinner_for] = React.useState<number | undefined>()
        const [show_num, set_show_num] = React.useState(100)
        const show_more = React.useCallback(() => {
            set_show_num(Math.min(show_num + 100, changes.length))
        }, [show_num, changes])
        const show_version = React.useCallback(
            (index_str: string) => {
                if (index_str === "latest") {
                    ui_actions.highlight_card(undefined)
                    ui_state.set_current_board_uid(not_null(current_board_uid), "latest")
                    return
                }
                const change = changes[parseInt(index_str)]
                ui_actions.hide_all_comments()
                set_show_spinner_for(change.board_version)
                ui_actions.fully_expand_all()
                ui_state.set_current_board_uid(
                    not_null(current_board_uid),
                    change.board_version,
                    () => {
                        set_show_spinner_for(undefined)
                        ui_actions.highlight_card(change.card?.uid)
                        if (change.patch_op_type === CommentPatchOpType.AddComment && change.card) {
                            // Note: We must use `ui_state.current_board.board` here, because `board`
                            //       is not up-to-date yet.
                            const card = not_null(
                                ui_state.current_board.board?.card(change.card.uid),
                            )
                            ui_actions.open_comments(card)
                        }
                        onChange?.()
                    },
                )
            },
            [current_board_uid, changes, onChange],
        )
        React.useEffect(() => {
            // Go to "latest version" whenever `show_my_changes` or `show_important_only` changes.
            ui_actions.highlight_card(undefined)
            ui_state.set_current_board_uid(not_null(current_board_uid), "latest")
        }, [current_board_uid, show_my_changes, show_important_only])
        const latest_board = current_board_uid ? board_resource.read(current_board_uid) : undefined
        const {can_view_whole_history, could_view_whole_history} = useCurrentBoardPermission()
        React.useEffect(() => {
            if (!latest_board) {
                set_changes([])
                return
            }
            set_changes(
                calculate_changes({
                    can_view_whole_history,
                    show_important_only,
                    show_my_changes,
                    latest_board,
                }),
            )
            // `latest_board?.last_change` is important, because we want to re-calculate the
            // list of changes even if the board changes locally only.
        }, [
            show_my_changes,
            show_important_only,
            latest_board,
            latest_board?.last_change,
            can_view_whole_history,
        ])
        React.useEffect(() => {
            if (search_visible) {
                list_ref.current?.focus_search_field()
            }
        }, [search_visible])
        const {can_edit_card, can_add_comment} = useCardPermission(latest_board?.root)
        const modifications_allowed_in_latest_version = can_edit_card || can_add_comment
        const is_plan_free_limit_reached =
            !can_view_whole_history &&
            show_num >= changes.length &&
            changes[changes.length - 1]?.board_version !== 1
        const are_there_versions_before_20210401 =
            !is_plan_free_limit_reached &&
            show_num >= changes.length &&
            changes[changes.length - 1]?.board_version !== 1 &&
            (latest_board?.first_change?.date.getTime() ?? 0) < new Date("2021-04-01").getTime()
        const show_cling_pro_teaser = React.useCallback(
            () =>
                ui_actions.open_pro_feature_teaser_dialog({
                    description: i18n.to_view_versions_older_than_7_days,
                }),
            [],
        )
        if (!current_board_uid) {
            return null
        }
        return (
            <SearchableList
                search_visible={!!search_visible}
                onSelected={show_version}
                search_label={i18n.search}
                className="board-history__list"
                ref={list_ref}
                data-test-id="BoardHistory_list"
            >
                <ListItem
                    key="latest"
                    value="latest"
                    selected={display_version === "latest"}
                    data-test-id="BoardHistory_version_latest"
                >
                    <div className="board-history__avatar">
                        <Icon
                            icon={modifications_allowed_in_latest_version ? "edit" : "history"}
                            outlined
                        />
                    </div>
                    <div className="board-history__entry">
                        <div className="board-history__entry-header">
                            {modifications_allowed_in_latest_version &&
                                i18n.modifications_are_allowed}
                        </div>
                        <div
                            className="board-history__entry-description"
                            data-test-id="BoardHistory_entry_desc"
                        >
                            {i18n.live}
                        </div>
                    </div>
                </ListItem>
                {changes
                    .filter((_, i) => i < show_num)
                    .map((x, i) => (
                        <ListItem
                            key={x.board_version}
                            value={`${i}`}
                            selected={x.board_version === display_version}
                            data-test-id={`BoardHistory_version_${x.board_version}`}
                        >
                            <div className="board-history__avatar">
                                {show_spinner_for === x.board_version && (
                                    <div className="board-history__avatar-loading">
                                        <LoadingIndicator delay={500} alpha={1} />
                                    </div>
                                )}
                                <PrincipalInfo
                                    className={classNames("board-history__avatar-pi", {
                                        "board-history__avatar-pi--loading":
                                            show_spinner_for === x.board_version,
                                    })}
                                    display="avatar"
                                    uid={x.account_uid}
                                    data-test-ignore
                                />
                                {x.date.getTime() >
                                    (ui_state.board_last_seen(current_board_uid)?.getTime() ??
                                        0) && (
                                    <div className="board-history__avatar-badge mdcx-badge mdcx-badge--secondary mdcx-badge--small" />
                                )}
                            </div>
                            <div className="board-history__entry">
                                <div className="board-history__entry-header">
                                    <RelDate date={x.date} />{" "}
                                    <PrincipalInfo
                                        data-test-ignore
                                        display="full_name_no_teams"
                                        uid={x.account_uid}
                                    />
                                </div>
                                {!!x.card && (
                                    <div
                                        className="board-history__entry-synopsis"
                                        data-test-id="BoardHistory_entry_synopsis"
                                    >
                                        <CardSynopsisOneLiner card={x.card} />
                                    </div>
                                )}
                                <div
                                    className="board-history__entry-description"
                                    data-test-id="BoardHistory_entry_desc"
                                >
                                    {x.desc}
                                </div>
                            </div>
                        </ListItem>
                    ))}
                {show_num < changes.length && (
                    <div className="width--full" data-ignore-in-search>
                        <Button onClick={show_more} className="width--full">
                            {i18n.show_older_versions}
                        </Button>
                    </div>
                )}
                {are_there_versions_before_20210401 && (
                    <div className="width--full font-caption mdc-list-item" data-ignore-in-search>
                        <div className="mt--1">
                            {i18n.due_to_technical_reasons_you_cannot_view_changes_made_before}
                        </div>
                    </div>
                )}
                {is_plan_free_limit_reached && could_view_whole_history && (
                    <div
                        className="width--full font-caption mdc-list-item"
                        data-ignore-in-search
                        onClick={show_cling_pro_teaser}
                    >
                        <div className="mt--1">
                            {i18n.to_view_versions_older_than_7_days} <HighlightBadge type="pro" />
                        </div>
                    </div>
                )}
                {/* Just a spacer (for NewElmFAB) */}
                <div className="board-history__list-bottom-spacer" data-ignore-in-search />
            </SearchableList>
        )
    },
)

const BoardHistory = observer(({is_open, on_close}: {is_open: boolean; on_close: () => void}) => {
    const [search_visible, set_search_visible] = React.useState(false)
    const {
        current_board: {board},
    } = React.useContext(BoardContext)
    const [show_my_changes, set_show_my_changes] = React.useState<boolean>(
        board?.acl.all.length === 1,
    )
    const [show_important_only, set_show_important_only] = React.useState<boolean>(true)
    const toggle_show_my_changes = React.useCallback(
        () => set_show_my_changes(!show_my_changes),
        [show_my_changes],
    )
    const toggle_show_important_only = React.useCallback(
        () => set_show_important_only(!show_important_only),
        [show_important_only],
    )
    const on_toggle_search = React.useCallback(
        () => set_search_visible(!search_visible),
        [search_visible],
    )
    const [collapsed, set_collapsed] = React.useState(false)
    const toggle_collapsed = React.useCallback(() => {
        set_collapsed(!collapsed)
        if (!running_on_phone()) {
            return
        }
        runInAction(() => {
            if (collapsed) {
                ui_state.app_bar_shown = true
                ui_state.app_bar_enabled = false
            } else {
                ui_state.app_bar_enabled = true
            }
        })
    }, [collapsed])
    const on_change_mobile = React.useCallback(() => {
        toggle_collapsed()
    }, [toggle_collapsed])
    React.useEffect(() => {
        if (is_open && running_on_phone()) {
            runInAction(() => {
                ui_state.app_bar_shown = true
                ui_state.app_bar_enabled = false
            })
        }
        if (!is_open) {
            set_collapsed(false)
            set_search_visible(false)
        }
    }, [is_open])
    const open_help = React.useCallback(
        () => ui_actions.open_website_page("detail/version-history"),
        [],
    )
    if (!board) {
        return null
    }
    return (
        <div
            className={classNames("board-history", {
                "board-history-desktop": !running_on_phone(),
                "board-history-mobile": running_on_phone(),
                "board-history--open": is_open,
                "board-history--collapsed": is_open && collapsed,
                "board-history--app-bar-shown":
                    ui_state.app_bar_shown && running_on_mobile_device(),
            })}
            onClick={cancel_event}
        >
            <GlobalBackdrop />
            <div className="board-history__header">
                {running_on_phone() && (
                    <IconButton
                        icon={collapsed ? "expand_less" : "expand_more"}
                        onClick={toggle_collapsed}
                        outlined
                        data-test-id="BoardHistory_toggle_collapsed"
                        data-test-state={collapsed ? "collapsed" : "expanded"}
                    />
                )}
                <div
                    className="board-history__header-title"
                    onClick={running_on_phone() ? toggle_collapsed : undefined}
                >
                    {i18n.version_history}
                </div>
                {!collapsed && (
                    <IconButton
                        icon={search_visible ? "search_off" : "search"}
                        onClick={on_toggle_search}
                        outlined
                        small={!running_on_phone()}
                        tooltip={i18n.search}
                    />
                )}
                <IconButton
                    onClick={open_help}
                    small={!running_on_phone()}
                    icon="help"
                    outlined
                    tooltip={i18n.help}
                />
                <IconButton
                    icon="close"
                    small={!running_on_phone()}
                    outlined
                    onClick={on_close}
                    data-test-id="BoardHistory_close"
                    tooltip={i18n.close}
                />
            </div>
            {is_open && (
                <>
                    <ChipSet className="p--1" onClick={cancel_event}>
                        <Chip primary={show_my_changes} onClick={toggle_show_my_changes}>
                            {i18n.my_changes}
                        </Chip>
                        <Chip
                            primary={show_important_only}
                            onClick={toggle_show_important_only}
                            data-test-id="BoardHistory_important_only"
                        >
                            {i18n.important_only}
                        </Chip>
                    </ChipSet>
                    <BoardHistoryList
                        key="board_history_list"
                        search_visible={search_visible}
                        onChange={running_on_phone() ? on_change_mobile : undefined}
                        show_my_changes={show_my_changes}
                        show_important_only={show_important_only}
                    />
                </>
            )}
        </div>
    )
})

export const ObservingBoardHistory = observer(() => {
    return (
        <BoardHistory is_open={board_history_state.is_open} on_close={board_history_state.close} />
    )
})

export function calculate_changes({
    show_my_changes,
    show_important_only,
    can_view_whole_history,
    latest_board,
}: {
    show_my_changes: boolean
    show_important_only: boolean
    can_view_whole_history: boolean
    latest_board: Board
}) {
    const changes_map = new Map<
        number,
        [
            CardChangelogEntry | CommentChangelogEntry | BoardChangelogEntry,
            Card | undefined,
            boolean /* `card_was_on_board` */,
            boolean /* `contains_important_change */,
        ]
    >()
    const set_change = (
        changelog_entry: CardChangelogEntry | CommentChangelogEntry | BoardChangelogEntry,
        card: Card | undefined,
        card_was_on_board: boolean,
    ) => {
        // If there are multiple operations in a single patch, we have to deterministically
        // determine, which one we want to display.
        const precedence = [
            // SetCardNoteSafeHtml is on top because we will add link-cards whenever the
            // user enters an URL in a text-card IN THE SAME PATCH. We want to show this as
            // "Note text changed".
            CardPatchOpType.SetCardNoteSafeHtml,
            CardPatchOpType.AddCard,
            CommentPatchOpType.AddComment,
            BoardPatchOpType.AddSystemBoard,
            BoardPatchOpType.AddPeopleBoard,
            BoardPatchOpType.AddBoard,
            CardPatchOpType.PasteCard,
            CardPatchOpType.SetCardFileBlob,
        ]
        const current = changes_map.get(changelog_entry.board_version)
        if (current) {
            let cur_precedence = precedence.indexOf(current[0].patch_op_type)
            if (cur_precedence === -1) {
                cur_precedence = Number.MAX_SAFE_INTEGER
            }
            let new_precedence = precedence.indexOf(changelog_entry.patch_op_type)
            if (new_precedence === -1) {
                new_precedence = Number.MAX_SAFE_INTEGER
            }
            if (new_precedence > cur_precedence) {
                if (important_change_op_types.includes(changelog_entry.patch_op_type)) {
                    current[3] = true
                }
                return
            }
        }
        changes_map.set(changelog_entry.board_version, [
            changelog_entry,
            card,
            card_was_on_board,
            important_change_op_types.includes(changelog_entry.patch_op_type) || !!current?.[3],
        ])
    }
    const changelog_is_clean_after = new Date("2021-04-01T00:00:00Z").getTime()
    const seven_days_ago = Date.now() - 7 * 24 * 60 * 60_000
    const changelog_filter = (x: ChangelogData) =>
        x.account_uid !== CLING_BOT &&
        (show_my_changes || x.account_uid !== current_user.account.uid) &&
        x.patch_op_type !== CardPatchOpType.CardChangelogMigration &&
        !x.board_uid &&
        // We cannot use changelog-data from before 2021-04-01 because it may contain
        // metadata generated on other boards with higher board-version-numbers
        // See ef25b6e39755d44348d93396c9cad875c0aec829 for the fix to that.
        // We should re-create all metadata eventually.
        x.date.getTime() > changelog_is_clean_after &&
        (can_view_whole_history || x.date.getTime() > seven_days_ago)
    // Note: BoardPatchOpType.CardChangelogMigration
    //       equals CardPatchOpType.CardChangelogMigration
    latest_board.changelog.filter(changelog_filter).forEach((x) => set_change(x, undefined, false))
    for (const card of latest_board.regular_cards) {
        let card_was_on_board = false
        card.changelog
            .filter(changelog_filter)
            // Make a copy because `collapse_card_changes` might alter entries.
            .map((x) => new CardChangelogEntry({...x}))
            .filter(collapse_card_changes())
            .forEach((x) => {
                set_change(x, card, card_was_on_board)
                card_was_on_board = card_was_on_board || !x.board_uid
            })
        for (const comment of card.comments.filter((x) => !x.removed)) {
            comment.changelog.filter(changelog_filter).forEach((x) => set_change(x, card, false))
        }
    }
    const result = []
    for (let i = latest_board.version; i > 0; i--) {
        const change = changes_map.get(i)
        if (change) {
            const [x, card, was_on_board_before, has_important_change] = change
            if (show_important_only && !has_important_change) {
                continue
            }
            result.push({
                board_version: x.board_version,
                card,
                account_uid: x.account_uid,
                patch_op_type: x.patch_op_type,
                date: x.date,
                desc: i18n.changelog_entry(x, was_on_board_before),
            })
        }
    }
    return result
}

/**
 * We want to collapse certain changes like consecutive operations on the same card by the same
 * user.
 * Note: Changelog entries are mutated!
 */
function collapse_card_changes() {
    let last_card_change: CardChangelogEntry | undefined
    return (x: CardChangelogEntry): boolean => {
        if (
            !last_card_change ||
            last_card_change.date.getTime() < x.date.getTime() - 60_000 ||
            last_card_change.account_uid !== x.account_uid ||
            last_card_change.board_version + 1 !== x.board_version
        ) {
            last_card_change = x
            return true
        }
        if (last_card_change.patch_op_type === x.patch_op_type) {
            last_card_change.board_version = x.board_version
            return false
        }
        if (last_card_change.patch_op_type === CardPatchOpType.AddCard) {
            last_card_change.board_version = x.board_version
            return false
        }
        last_card_change = x
        return true
    }
}

export default ObservingBoardHistory
