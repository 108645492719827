import * as React from "react"
import {observer} from "mobx-react"
import type {BoardStyleStruct, BoardUID} from "@cling/lib.shared.model"
import {background_style} from "@cling/lib.web.layout"
import {board_background_image_url} from "@cling/lib.web.resources/thumbnails"
import {media_info_resource} from "@cling/lib.web.resources"

/**
 * Render the current and the previous board's background and cross-fade.
 */
export const BoardBackground = observer(
    ({board_uid, board_style}: {board_uid: BoardUID; board_style: BoardStyleStruct}) => {
        const [image_url, set_image_url] = React.useState("")
        const [prev_board_uid, set_prev_board_uid] = React.useState<undefined | BoardUID>()
        const timeout = React.useRef<any>()
        // This is a bit of a hack. We request the media-info only because once it's loaded,
        // we can be sure that the image is available.
        if (board_style.background_image_blob) {
            media_info_resource.read(board_style.background_image_blob.uid)
        }
        const url = board_background_image_url(board_style)
        React.useEffect(() => {
            clearTimeout(timeout.current)
            if (!url) {
                set_image_url("")
                return
            }
            if (board_uid !== prev_board_uid) {
                // Change the image immediately if the board has changed.
                set_prev_board_uid(board_uid)
                set_image_url(url)
                return
            }
            // Otherwise, wait until the image is loaded or an error occurs.
            const img = new Image()
            img.onload = () => {
                clearTimeout(timeout.current)
                set_image_url(url)
            }
            img.onerror = () => {
                clearTimeout(timeout.current)
                set_image_url(url)
            }
            img.src = url
            timeout.current = setTimeout(() => {
                set_image_url(url)
            }, 5000)
        }, [url, board_uid, prev_board_uid, board_style])
        return (
            <div
                className="board-background"
                style={background_style({
                    url: image_url,
                    color: board_style.background_color,
                    is_pattern: board_style.background_is_pattern,
                })}
            />
        )
    },
)
