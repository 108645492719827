/**
 * Code that is only relevant if the board is viewed as part of our homepage.
 */
import {report_error, report_info} from "@cling/lib.shared.debug"
import type {Board, NoteCard} from "@cling/lib.shared.model"
import {not_null} from "@cling/lib.shared.utils"
import {board_resource} from "@cling/lib.web.resources"
import {runInAction} from "mobx"
import {ui_state} from "../state/index"
import {set_user_currency} from "../startup/startup_common"

export async function inject_cling_hp_code({cling_hp_info_page}: {cling_hp_info_page?: string}) {
    if (!location.pathname.match(/\/(de|en)\/(index.html|pricing.html|info\/)/)) {
        return
    }
    const board = await board_resource.wait_for_sync(ui_state.current_board_uid!)
    if (location.pathname.endsWith("/pricing.html")) {
        const pro_card = board.regular_cards.find(
            (x) =>
                x.note?.title.startsWith("Cling Pro - starting at") ||
                x.note?.title.startsWith("Cling Pro - ab"),
        )
        const team_card = board.regular_cards.find(
            (x) =>
                x.note?.title.startsWith("Cling for Teams - starting at") ||
                x.note?.title.startsWith("Cling für Teams - ab"),
        )
        if (!pro_card || !team_card) {
            report_error("Error on pricing page - cannot find placeholders")
            return
        }
        await set_user_currency()
        if (ui_state.preferred_currency === "USD") {
            pro_card.note!.title = pro_card.note!.title.replace("at", "at $ 4.40")
            pro_card.note!.title = pro_card.note!.title.replace("ab", "ab $ 4,40")
            team_card.note!.title = team_card.note!.title.replace("at", "at $ 8.80")
            team_card.note!.title = team_card.note!.title.replace("ab", "ab $ 8,80")
        } else {
            pro_card.note!.title = pro_card.note!.title.replace("at", "at € 4")
            pro_card.note!.title = pro_card.note!.title.replace("ab", "ab € 4")
            team_card.note!.title = team_card.note!.title.replace("at", "at € 8")
            team_card.note!.title = team_card.note!.title.replace("ab", "ab € 8")
        }
        return
    }
    if (location.pathname.includes("/info/")) {
        adapt_info_board(board, cling_hp_info_page)
    }
    remove_all_but_first_column(board)
}

/**
 * If `cling_hp_info_page` is set, then its contents will replace the board title and the first two
 * cards.
 */
function adapt_info_board(board: Board, cling_hp_info_page?: string) {
    if (!cling_hp_info_page) {
        cling_hp_info_page = location.pathname
            .split("/")
            .pop()
            ?.replace(".html", "")
            ?.replace(/-/g, "_")
        if (!cling_hp_info_page) {
            return
        }
    }
    const config_card = board.regular_cards.find(
        (x) => x.note?.title === cling_hp_info_page,
    ) as NoteCard
    if (!config_card) {
        report_info("[info-page] No configuration found -- showing the first column", {
            cling_hp_info_page,
        })
        return
    }
    const first_column = not_null(board.columns.find((x) => x.children.length > 0))
    runInAction(() => {
        const text = config_card.note.safe_html.replace(/<.*?>/g, "").replace(/<\/.*?>/g, "")
        const title = text.match(/^Title:\s(.*)$/)?.[1]
        if (title) {
            board.name = title
        } else {
            report_error("[info-page] No title found -- using the regular board title", {
                cling_hp_info_page,
            })
        }
        first_column.children.splice(0, 2)
        first_column.children.unshift(...config_card.children)
        config_card.children = []
        board.sanitize()
    })
    return board
}

function remove_all_but_first_column(board: Board) {
    runInAction(() => {
        let found = false
        for (const x of board.columns) {
            if (found || x.children.length === 0) {
                x.parent.children.splice(x.parent_pos, 1)
            } else {
                found = x.children.length > 0
            }
        }
    })
}
