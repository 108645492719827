import {observable, runInAction, makeObservable} from "mobx"
import type {BoardUID, BoardStyle, AccountUID, BoardType} from "@cling/lib.shared.model"

type MinBoardProps = {
    uid: BoardUID
    name: string
    board_style: BoardStyle
    board_type: BoardType
    people_board_shared_with: (current_user: AccountUID) => AccountUID
}

class MeetState {
    private _board: MinBoardProps | undefined = undefined

    join = (board: MinBoardProps) => {
        runInAction(() => (this._board = board))
    }

    hangup = () => {
        runInAction(() => (this._board = undefined))
    }

    constructor() {
        makeObservable<MeetState, "_board">(this, {
            _board: observable,
        })
    }

    get board() {
        return this._board
    }
}

export const meet_state = new MeetState()
