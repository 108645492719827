import * as React from "react"
import {RouteComponentProps, Router} from "@reach/router/index"
import {Snackbar, StickySnackbar} from "@cling/lib.web.mdc"
import {BoardPage} from "./pages/board_page"
import {assert, encode_b62, error_to_string, sleep} from "@cling/lib.shared.utils"
import {ExportBoardToast, ImportBoardToast, UploadProgressToast} from "./toasts/index"
import {observer} from "mobx-react"
import {current_user, ui_actions, ui_state} from "./state/index"
import {
    as_BoardUID,
    as_CardUID,
    board_uid_type,
    board_vanity_uri,
    BoardType,
    BoardUID,
    BoardUID_prefix,
    CardUID,
    derive_dashboard_uid,
    is_BoardUID,
    is_CardUID,
    PageView,
    SearchBoardsRequest,
    SearchBoardsResponse,
} from "@cling/lib.shared.model"
import {report_error, report_info} from "@cling/lib.shared.debug"
import {Debug} from "./debug/debug"
import {running_on_mobile_device} from "@cling/lib.web.utils"
import {KeyboardShortcuts} from "./misc/keyboard_shortcuts"
import {fatal_error_url} from "@cling/lib.web.utils/fatal_error_url"
import {parse as parse_search_query} from "@cling/lib.shared.search/search_query_parser"
import {log} from "@cling/lib.shared.logging"
import {DialogContainer} from "./dialogs/dialog_container"
import {is_elm_inside_an_active_rich_text_editor} from "@cling/lib.web.rich_text_editor"
import {ImportDataToast} from "./import_data/import_data_toast"
import {Buffer} from "buffer"
import {sensors} from "./debug/sensors"
import type {UID} from "@cling/lib.shared.types/common"
import {BoardContext} from "./board_context"
import {autorun, reaction, runInAction} from "mobx"
import {board_name} from "./board/board_name"
import {i18n} from "@cling/lib.web.i18n"
import {send_message_to_worker} from "./startup/worker_gateway"
import {classNames} from "@cling/lib.web.utils"
import {board_info_resource, board_resource} from "@cling/lib.web.resources"
import {categorize_error} from "@cling/lib.shared.error"
import {goto_dashboard} from "./utils"
import {board_history_state} from "./board/board_history"
import {GlobalDropAndPasteHandler} from "./misc/global_drop_and_paste"
import {report_user_event} from "@cling/lib.web.analytics"
import {can_call_faas} from "@cling/lib.web.auth"
import {call_function} from "@cling/lib.shared.faas"
import {Card} from "./card/card"
import {React_lazy, React_suspense} from "@cling/lib.web.lazy_load/suspense"
import {meet_state} from "./meet/meet_state"
import {video_player_state} from "./card/video_player"
import {SimpleTooltipContainer} from "@cling/lib.web.mdc/simple_tooltip"
import AuthorizeAppPage from "./pages/authorize_app_page"
const OrganizationsPage = React_lazy(
    () => import(process.env.F_PUBLIC_LAZY || "@cling/client.web_app/team/organizations_page"),
)
const AddOrganizationPage = React_lazy(
    () => import(process.env.F_PUBLIC_LAZY || "@cling/client.web_app/team/add_organization_page"),
)
const DragAndDropGhost = React_lazy(
    () => import(process.env.F_PUBLIC_LAZY || "@cling/client.web_app/drag_and_drop"),
)
const Meet = React_lazy(() =>
    import(process.env.F_PUBLIC_LAZY || "@cling/client.web_app/meet/meet").then((x) => ({
        default: x.Meet,
    })),
)
const VideoPlayerContainer = React_lazy(() => import("@cling/client.web_app/card/video_player"))
const ShareTargetPage = React_lazy(
    () => import(process.env.F_PUBLIC_LAZY || "@cling/client.web_app/pages/share_target_page"),
)
const EmailMarkupPage = React_lazy(
    () => import(process.env.F_PUBLIC_LAZY || "@cling/client.web_app/pages/email_markup_page"),
)
const CheckoutPage = React_lazy(
    () => import(process.env.F_PUBLIC_LAZY || "@cling/client.web_app/pages/checkout_page"),
)

export class App extends React.Component {
    private dispose_on_unmount: (() => void)[] = []
    constructor(props: any) {
        super(props)
        if (running_on_mobile_device()) {
            // Suppress context menus on mobile for the most part as they interfere with
            // long press.
            const suppress_context_menu = () => {
                const listener = (e: any) => {
                    const target = e.target as HTMLElement
                    if (
                        target.tagName === "INPUT" ||
                        is_elm_inside_an_active_rich_text_editor(target)
                    ) {
                        return
                    }
                    e.stopImmediatePropagation()
                    e.preventDefault()
                    e.stopPropagation()
                }
                document.addEventListener("contextmenu", listener)
                return () => window.removeEventListener("contextmenu", listener)
            }
            this.dispose_on_unmount.push(suppress_context_menu())
        } else if (!process.env.F_PUBLIC) {
            this.dispose_on_unmount.push(
                autorun(() => {
                    const {board} = ui_state.current_board
                    if (!board) {
                        return
                    }
                    const new_vanity = location.pathname.replace(
                        /(\/c\/[^?#]*)/,
                        board_vanity_uri(board.uid, board_name(board)),
                    )
                    window.history.replaceState(
                        history.state,
                        "",
                        `${new_vanity}${location.search}${location.hash}`,
                    )
                }),
            )
        }
        this.dispose_on_unmount.push(
            reaction(
                () => ui_state.highlighted_card_state,
                (highlighted_card_state) => {
                    const board = ui_state.current_board.board
                    if (!highlighted_card_state || !board) {
                        return
                    }
                    if (!board.contains(highlighted_card_state.card_uid)) {
                        // The card is not on the board, perhaps on trashcan or clipboard.
                        // Note: If we run on mobile, the trashcan or clipboard will be
                        // opened as a board directly.
                        if (!running_on_mobile_device()) {
                            if (
                                board_resource.clipboard.contains(highlighted_card_state.card_uid)
                            ) {
                                if (!ui_state.clipboard_shown) {
                                    ui_actions.toggle_clipboard_shown()
                                }
                            }
                            if (board_resource.trashcan.contains(highlighted_card_state.card_uid)) {
                                if (!ui_state.trashcan_shown) {
                                    ui_actions.toggle_trashcan_shown()
                                }
                            }
                        }
                    }
                },
                {fireImmediately: true, name: "app.tsx - highlighted_card_uid"},
            ),
        )
    }

    componentWillUnmount() {
        for (const dispose of this.dispose_on_unmount) {
            dispose()
        }
        this.dispose_on_unmount = []
    }

    async componentDidCatch(error: Error) {
        if (process.env.NODE_ENV === "test") {
            throw error
        }
        report_error("App.componentDidCatch() - sending user to /oops", error)
        goto(
            fatal_error_url(
                "App.componentDidCatch(...)\n\ncomponent stack:\n" + error_to_string(error),
            ),
        )
    }

    render() {
        log.debug(`App.render() -- location.pathname: ${location.pathname}`)
        return (
            <>
                <BoardContext.Provider value={ui_state}>
                    <Router>
                        {!process.env.F_PUBLIC && (
                            <BoardOrCardPage
                                vanity_text_and_uid={derive_dashboard_uid(current_user.account.uid)}
                                default
                            />
                        )}
                        {process.env.F_PUBLIC && <PublicBoardWithCustomURI default />}
                        <BoardOrCardPage path="/c/:vanity_text_and_uid" />
                        <BoardOrCardPage path="/c/:vanity_text_and_uid/comments" open_comments />
                        <BoardOrCardPage path="/c/:vanity_text_and_uid/:card_uid" />
                        <BoardOrCardPage
                            path="/c/:vanity_text_and_uid/:card_uid/comments"
                            open_comments
                        />
                        {!process.env.F_PUBLIC && <LazyShareTargetPage path="/c/share_target" />}
                        {!process.env.F_PUBLIC && <LazyCheckoutPage path="/c/checkout" />}
                        {!process.env.F_PUBLIC && <AuthorizeAppPage path="/c/authorize" />}
                        {!process.env.F_PUBLIC && (
                            <LazyEmailMarkupPage path="/c/email_markup/:board_uid" />
                        )}
                        {!process.env.F_PUBLIC && <LazyOrganizationsPage path="/c/organizations" />}
                        {!process.env.F_PUBLIC && (
                            <LazyOrganizationsPage path="/c/organizations/:organization_uid/:uid_or_screen/:filter" />
                        )}
                        {!process.env.F_PUBLIC && (
                            <LazyAddOrganizationPage path="/c/organizations/add" />
                        )}
                    </Router>
                    <BoardPage />
                    {!process.env.F_PUBLIC && <LazyDragAndDropGhost />}
                    {!process.env.F_PUBLIC && <ImportBoardToast />}
                    {!process.env.F_PUBLIC && <ImportDataToast />}
                    {!process.env.F_PUBLIC && <ExportBoardToast />}
                    {!process.env.F_PUBLIC && <UploadProgressToast />}
                    <DialogContainer />
                    {!process.env.F_PUBLIC && <NewVersionAvailableHint />}
                    {!process.env.F_PUBLIC && <KeyboardShortcuts />}
                    <ObservingSnackbar />
                    {!process.env.F_PUBLIC && !running_on_mobile_device() && (
                        <GlobalDropAndPasteHandler />
                    )}
                    {!process.env.F_PUBLIC && <LazyMeet />}
                    <LazyVideoPlayerContainer />
                    <Debug />
                </BoardContext.Provider>
                {!running_on_mobile_device() && <SimpleTooltipContainer />}
            </>
        )
    }
}

const BoardOrCardPage = ({
    vanity_text_and_uid,
    card_uid,
    open_comments,
}: RouteComponentProps<{
    vanity_text_and_uid: string
    card_uid?: CardUID
    open_comments?: boolean
}>) => {
    const set_ui_state_current_board_uid = React.useCallback(
        (props_board_uid?: BoardUID, props_card_uid?: CardUID, props_open_comments?: boolean) => {
            let board_uid = props_board_uid
            if (!is_BoardUID(board_uid)) {
                goto("/board_invalid")
                return
            }
            if (ui_state.current_board_uid === board_uid) {
                ui_actions.highlight_card(props_card_uid)
                return
            }
            const board_type = board_uid_type(board_uid)
            if (!running_on_mobile_device()) {
                // On desktop we do not open these system boards directly but rather open the
                // corresponding aux column on the dashboard.
                if ([BoardType.trashcan, BoardType.clipboard].includes(board_type)) {
                    board_uid = derive_dashboard_uid(current_user.account.uid)
                }
            }
            if (!NProgress.started()) {
                NProgress.start(0.5, {delay: 200})
            }
            // Load the board and react to errors.
            board_resource
                .wait_for_sync(board_uid)
                .then(() => {
                    NProgress.done()
                })
                .catch(async (error) => {
                    NProgress.done()
                    if ([403, 404, 410].includes(categorize_error(error).status)) {
                        goto("/board_invalid")
                    } else {
                        if (board_uid_type(as_BoardUID(board_uid)) === BoardType.dashboard) {
                            goto(fatal_error_url(error))
                        } else {
                            report_error(
                                "Board could not be synced - redirecting user to dashboard",
                                error,
                            )
                            goto_dashboard({replace: true}).catch(report_error)
                        }
                    }
                })
            sensors.expect_board_will_be_shown(board_uid)
            runInAction(() => {
                board_history_state.close()
                if (ui_state.set_current_board_uid(board_uid!, "latest")) {
                    if (!running_on_mobile_device()) {
                        if (board_type === BoardType.clipboard && !ui_state.clipboard_shown) {
                            ui_actions.toggle_clipboard_shown()
                        }
                        if (board_type === BoardType.trashcan && !ui_state.trashcan_shown) {
                            ui_actions.toggle_trashcan_shown()
                        }
                    }
                    ui_actions.highlight_card(props_card_uid)
                    if (props_open_comments && props_card_uid) {
                        ui_actions.open_comments(props_card_uid)
                    }
                }
            })
        },
        [],
    )
    const [prev_uid, set_prev_uid] = React.useState<UID>()
    const uid = vanity_text_and_uid?.includes("-")
        ? vanity_text_and_uid?.split("-").pop()
        : vanity_text_and_uid
    React.useEffect(() => {
        if (uid === prev_uid) {
            return
        }
        if (!process.env.F_PUBLIC && (is_CardUID(uid) || card_uid)) {
            const search_card_uid = card_uid || as_CardUID(uid)
            sensors.expect_board_will_be_shown()
            board_uid_by_card_uid(
                search_card_uid,
                is_BoardUID(uid) ? 8_000 : 30_000,
                is_BoardUID(uid) ? uid : undefined,
            )
                .then((board_uid) => {
                    if (!board_uid) {
                        if (is_BoardUID(uid)) {
                            // Card has not been found - fall back to just viewing the board.
                            log.debug(
                                `BoardOrCardPage.render() -- board_uid: ${JSON.stringify(uid)}`,
                            )
                            sensors.expect_board_will_be_shown(uid)
                            ui_state.search_state.end_search()
                            set_prev_uid(uid)
                            set_ui_state_current_board_uid(uid)
                        } else {
                            goto("/board_invalid")
                        }
                        return
                    }
                    log.debug(`BoardOrCardPage.render() -- board_uid: ${JSON.stringify(board_uid)}`)
                    sensors.expect_board_will_be_shown(board_uid)
                    ui_state.search_state.end_search()
                    set_prev_uid(uid as UID)
                    set_ui_state_current_board_uid(board_uid, search_card_uid, open_comments)
                })
                .catch((error) => {
                    report_error(error)
                    goto("/board_invalid")
                })
        } else {
            let cur_board_uid: BoardUID
            if (is_BoardUID(uid)) {
                cur_board_uid = uid
            } else {
                // This might be a legacy board-uid.
                try {
                    cur_board_uid = as_BoardUID(
                        BoardUID_prefix + encode_b62(Buffer.from(uid!.substring(2), "hex")),
                    )
                } catch {
                    goto("/board_invalid")
                    return
                }
            }
            log.debug(`BoardOrCardPage.render() -- board_uid: ${JSON.stringify(cur_board_uid)}`)
            sensors.expect_board_will_be_shown(cur_board_uid)
            ui_state.search_state.end_search()
            set_prev_uid(cur_board_uid)
            set_ui_state_current_board_uid(cur_board_uid, card_uid, open_comments)
        }
    }, [uid, prev_uid, card_uid, set_ui_state_current_board_uid, open_comments])
    useBoardChange()
    return null
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function PublicBoardWithCustomURI(_: RouteComponentProps) {
    useBoardChange()
    return null
}

function LazyCheckoutPage(props: RouteComponentProps) {
    return (
        <React_suspense>
            <CheckoutPage {...props} />
        </React_suspense>
    )
}

function LazyEmailMarkupPage(props: RouteComponentProps) {
    return (
        <React_suspense>
            <EmailMarkupPage {...props} />
        </React_suspense>
    )
}

function LazyShareTargetPage(props: RouteComponentProps) {
    return (
        <React_suspense>
            <ShareTargetPage {...props} />
        </React_suspense>
    )
}

function LazyOrganizationsPage(props: RouteComponentProps) {
    return (
        <React_suspense>
            <OrganizationsPage {...props} />
        </React_suspense>
    )
}

function LazyAddOrganizationPage(props: RouteComponentProps) {
    return (
        <React_suspense>
            <AddOrganizationPage {...props} />
        </React_suspense>
    )
}

function LazyDragAndDropGhost() {
    return (
        <React_suspense>
            <DragAndDropGhost render_card={Card} />
        </React_suspense>
    )
}

const LazyMeet = observer(() => {
    if (!meet_state.board) {
        return null
    }
    return (
        <React_suspense>
            <Meet />
        </React_suspense>
    )
})

const LazyVideoPlayerContainer = observer(() => {
    if (!video_player_state.url) {
        return null
    }
    return (
        <React_suspense>
            <VideoPlayerContainer />
        </React_suspense>
    )
})

let last_board_uid: BoardUID | undefined
let last_board_change_at: Date | undefined

const useBoardChange = () => {
    React.useEffect(() => {
        const current_board_uid = ui_state.current_board_uid
        if (last_board_uid === current_board_uid || !current_board_uid) {
            return
        }
        if (last_board_change_at && last_board_uid) {
            ui_actions.update_board_last_seen(last_board_uid, last_board_change_at)
        }
        last_board_uid = current_board_uid
        // Wait until board is synced. It should be displayed then.
        board_resource
            .wait_for_sync(current_board_uid)
            .then((board) => {
                Snackbar.clear()
                const event = report_user_event({page_view: new PageView({})})
                last_board_change_at = event.seen.date
                last_board_uid = current_board_uid
                if (
                    !process.env.F_PUBLIC &&
                    // Only show the toast if the user has explicit access to the board
                    // and not just because it is public.
                    board.acl.entries(current_user.account_attributes).length > 0 &&
                    ui_state.is_new_or_changed(board.board_info)
                ) {
                    Snackbar.show_message(i18n.there_are_new_changes, i18n.show, () =>
                        board_history_state.open(),
                    )
                }
            })
            .catch((error) => {
                // We ignore this error here. The error handling for missing boards is done in
                // `BoardOrCardPage`.
                report_info(error)
            })
    })
}

const ObservingSnackbar = observer(() => (
    <Snackbar
        className={classNames("app__snackbar", {
            "app__snackbar--with-bottom-app-bar": ui_state.app_bar_shown,
        })}
    />
))

const NewVersionAvailableHint = observer(() => {
    const update_web_app = React.useCallback(() => {
        const reason = "New version available, user choose to update"
        send_message_to_worker({type: "reload", reason})
        reload(reason)
    }, [])
    const {current_board} = React.useContext(BoardContext)
    if (!ui_state.show_new_version_available) {
        return null
    }
    if (!current_board) {
        // Only show if we are on a board page.
        return null
    }
    return (
        <StickySnackbar
            className={classNames("app__snackbar", {
                "app__snackbar--with-bottom-app-bar": ui_state.app_bar_shown,
            })}
            message={i18n.new_version_available}
            action_button_label={i18n.update}
            action={update_web_app}
        />
    )
})

async function board_uid_by_card_uid(
    card_uid: CardUID,
    timeout = 30_000,
    board_uid?: BoardUID,
): Promise<BoardUID | undefined> {
    let found_on_server: BoardUID | undefined
    if (board_uid) {
        const board = board_resource.read(board_uid)
        if (board?.contains(card_uid)) {
            return board.uid
        }
    }
    const query_server = async () => {
        const {matches} = await call_function(
            new SearchBoardsRequest({card_uid}),
            SearchBoardsResponse,
        )
        if (matches.length === 1) {
            found_on_server = matches[0].board_uid
            return found_on_server
        }
        return undefined
    }
    // Go and find the board the given card belongs to. Keep in mind that we fetch all boards
    // in the background so the query should give the right board-uid eventually.
    let retry_until = Date.now() + timeout
    let server_queried = false
    for (;;) {
        if (can_call_faas() && !server_queried) {
            server_queried = true
            query_server().catch(report_error)
        }
        if (found_on_server) {
            return found_on_server
        }
        const query = parse_search_query(`card.uid:${card_uid} archived:show`)
        assert(!query.invalid, "Query must not be invalid")
        const {matches, indexed_board_uids} = await ui_state.search_state.call_search(query)
        if (matches.length > 0) {
            const matched_board_uid = matches[0].board_uid
            assert(
                is_BoardUID(matched_board_uid),
                `BoardUID returned by search is not a board-uid: ${matched_board_uid}`,
            )
            return matched_board_uid
        } else if (Date.now() > retry_until) {
            return undefined
        }
        if (indexed_board_uids.length >= board_info_resource.read_all().length) {
            // All boards are indexed, so we can lower the wait time.
            retry_until -= 2_000
        }
        await sleep(process.env.NODE_ENV === "test" ? 10 : 1000)
    }
}
