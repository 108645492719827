import * as React from "react"
import {observer} from "mobx-react"
import {Menu} from "@cling/lib.web.mdc"
import type {BoardUID} from "@cling/lib.shared.model"
import {board_chooser_items, BoardChooserItem} from "./board_chooser_items"
import {i18n} from "@cling/lib.web.i18n"
import {running_on_mobile_device} from "@cling/lib.web.utils"

interface Props {
    anchor: React.ReactElement
    onSelected: (board_uid: BoardUID) => void
    onOpen?: () => void
    onClose?: () => void
    is_open: boolean
    sort_boards_alphabetically: boolean
    items: BoardChooserItem[]
    first_menu_item?: any
    "data-test-id"?: string
}

export const BoardChooserMenu = observer(
    ({
        anchor,
        items,
        first_menu_item,
        onOpen,
        onClose,
        onSelected,
        sort_boards_alphabetically,
        "data-test-id": data_test_id,
        is_open,
    }: Props) => {
        const menu_ref = React.createRef<Menu>()
        React.useEffect(() => {
            if (is_open) {
                menu_ref.current?.open_menu()
            } else {
                menu_ref.current?.close_menu()
            }
        })
        const menu_items = board_chooser_items({
            element_type: "MenuItem",
            items,
            sort_boards_alphabetically,
        })
        return (
            <Menu
                ref={menu_ref}
                searchable
                data-test-id={data_test_id}
                search_label={i18n.search}
                search_initial_focus={!running_on_mobile_device()}
                search_pos={running_on_mobile_device() ? "bottom" : "top"}
                first_menu_item={first_menu_item}
                onOpen={onOpen}
                onClose={onClose}
                onSelected={onSelected as any}
                anchor={
                    <>
                        {anchor}
                        {items.some((x) => x.show_badge) && (
                            <div className="board-chooser__new-or-changed-badge mdcx-badge mdcx-badge--small mdcx-badge--secondary">
                                &nbsp;
                            </div>
                        )}
                    </>
                }
                className="board-chooser__menu"
            >
                {menu_items}
            </Menu>
        )
    },
)
