import type {
    MessageFromWorker,
    Ping,
    PublishPatch,
    Reload,
    RequestSync,
    RequestSyncAllFromStore,
    Search,
    SendAuthState,
    SendSearchResult,
    SyncUpdate,
    PatchPublished,
    AddProvisionalMediaInfo,
} from "@cling/lib.web.worker/worker_interface"
import {log} from "@cling/lib.shared.logging"
import {assert_never} from "@cling/lib.shared.utils"
import type {EventBus} from "@cling/lib.web.primitives"
import {register_auth_listener, enforce_refresh} from "@cling/lib.web.auth"
import {fatal_error_url} from "@cling/lib.web.utils/fatal_error_url"
import {report_info} from "@cling/lib.shared.debug"
import {sensors} from "../debug/sensors"
import type {AuthInfo} from "@cling/lib.shared.model"
import {Snackbar} from "@cling/lib.web.mdc"
import {i18n} from "@cling/lib.web.i18n"
import {open_intercom} from "../misc/intercom"

let _port: Pick<MessagePort, "postMessage">
let last_pong_received_at = 0

function handle_message_from_worker({
    sync_updates_bus,
    search_bus,
    publish_patch_bus,
}: {
    sync_updates_bus: EventBus<SyncUpdate>
    search_bus: EventBus<Search | SendSearchResult>
    publish_patch_bus: EventBus<PublishPatch | PatchPublished>
}) {
    return (event: MessageEvent) => {
        const msg = event.data.msg as MessageFromWorker
        if (msg.type === "log") {
            const {severity, context, message, extra} = msg
            let prefix
            if (navigator.userAgent.includes("Chrome/") && !window._under_test) {
                // Colorize the context (cyan background).
                prefix = `\x1b[46m[${context}]\x1b[91m\x1b[0m `
            } else {
                prefix = `[${context}] `
            }
            log[severity](`${prefix}${message}`, extra)
        } else if (msg.type === "pong") {
            last_pong_received_at = Date.now()
            sensors.set_network_state(msg.offline ? "offline" : "online")
        } else if (
            msg.type === "sync_put" ||
            msg.type === "sync_remove" ||
            msg.type === "sync_up_to_date" ||
            msg.type === "sync_error"
        ) {
            sync_updates_bus.postMessage(msg)
        } else if (msg.type === "fatal_error") {
            goto(fatal_error_url(msg.error))
        } else if (msg.type === "send_search_result") {
            search_bus.postMessage(msg)
        } else if (msg.type === "reload") {
            reload(`Received "reload" message from worker: ${msg.reason}`)
        } else if (msg.type === "patch_published") {
            publish_patch_bus.postMessage(msg)
            sensors.patch_published(msg.patch_uid)
            if (msg.cling_error_code) {
                Snackbar.show_message(
                    `${i18n.changes_could_not_be_saved} ${i18n.cling_error_code_msg(
                        msg.cling_error_code,
                        "",
                    )}`,
                    i18n.help,
                    () => open_intercom("new_message"),
                )
            }
        } else if (msg.type === "refresh_auth") {
            log.info("Worker told us to refresh auth")
            enforce_refresh()
        } else {
            throw assert_never(msg)
        }
    }
}

export function send_message_to_worker(msg: Reload) {
    _port?.postMessage({msg})
}

export function init({
    auth_info_provider,
    csrf_token_provider,
    port,
    sync_updates_bus,
    request_sync_bus,
    publish_patch_bus,
    search_bus,
    add_provisional_media_info_bus,
    ping_message,
}: {
    auth_info_provider: () => AuthInfo
    csrf_token_provider: () => string
    port: Pick<MessagePort, "postMessage" | "addEventListener">
    sync_updates_bus: EventBus<SyncUpdate>
    request_sync_bus: EventBus<RequestSync | RequestSyncAllFromStore>
    publish_patch_bus: EventBus<PublishPatch | PatchPublished>
    search_bus: EventBus<Search | SendSearchResult>
    add_provisional_media_info_bus: EventBus<AddProvisionalMediaInfo>
    ping_message: Ping
}) {
    _port = port
    port.addEventListener(
        "message",
        handle_message_from_worker({
            sync_updates_bus,
            search_bus,
            publish_patch_bus,
        }),
    )
    request_sync_bus.addEventListener((event) => {
        port.postMessage({msg: event.data})
    })
    publish_patch_bus.addEventListener((event) => {
        const msg = event.data
        if (msg.type === "publish_patch") {
            port.postMessage({msg: event.data})
        } else if (msg.type === "patch_published") {
            // Ignored
        } else {
            assert_never(msg)
        }
    })
    search_bus.addEventListener((event) => {
        const msg = event.data
        if (msg.type === "search") {
            port.postMessage({msg: event.data})
        } else if (msg.type === "send_search_result") {
            // Ignored
        } else {
            assert_never(msg)
        }
    })
    add_provisional_media_info_bus.addEventListener((event) => {
        const msg = event.data
        if (msg.type === "add_provisional_media_info") {
            port.postMessage({msg: event.data})
        }
    })
    register_auth_listener(({account_uid, team_uids, admin_organization_uids}) => {
        log.debug(`[worker_gateway] Sending auth state to worker ...`)
        const msg: SendAuthState = {
            type: "send_auth_state",
            csrf_token: csrf_token_provider(),
            auth_info: {account_uid, team_uids, admin_organization_uids},
        }
        port.postMessage({msg})
    })
    // Send `auth_state` and CSRF token to the worker.
    port.postMessage({
        msg: {
            type: "send_auth_state",
            auth_info: auth_info_provider(),
            csrf_token: csrf_token_provider(),
        } satisfies SendAuthState,
    })
    start_watchdog(port, ping_message)
}

function start_watchdog(port: {postMessage: (message: {msg: Ping}) => void}, ping_message: Ping) {
    let last_ping_sent_at = 0
    const send_ping = () => {
        port.postMessage({msg: ping_message})
        last_ping_sent_at = Date.now()
    }
    setInterval(() => {
        const now = Date.now()
        if (now - last_ping_sent_at < 11_000 && now - last_pong_received_at > 31_000) {
            report_info(
                `Did not receive pong within 30 seconds -- ${JSON.stringify({
                    now,
                    last_ping_sent_at,
                    last_pong_received_at,
                })}`,
            )
        }
        send_ping()
    }, 10_000)
}
