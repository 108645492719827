import {nop} from "@cling/lib.shared.utils"

type Listener<T> = (msg: {data: T}) => void

/**
 * An eventbus that will broadcast messages to all other tabs *and* itself.
 */
export interface EventBus<T> {
    postMessage: (msg: T) => void
    addEventListener: (listener: Listener<T>) => void
}

export function blackhole_eventbus<T>(): EventBus<T> {
    return {
        postMessage: nop,
        addEventListener: nop,
    }
}

export function local_eventbus<T>(): EventBus<T> {
    const listeners: Listener<T>[] = []
    return {
        addEventListener: (listener) => {
            listeners.push(listener)
        },
        postMessage: (msg) => {
            listeners.forEach((x) => x({data: msg}))
        },
    }
}
