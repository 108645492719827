import * as React from "react"
import {report_error} from "@cling/lib.shared.debug"
import {call_function} from "@cling/lib.shared.faas"
import {
    create_PatchUID,
    PatchAccountSettings,
    PatchFullAccountRequest,
    TriState,
} from "@cling/lib.shared.model"
import {i18n} from "@cling/lib.web.i18n"
import {runInAction} from "mobx"
import {current_user} from "../state/index"
import {observer} from "mobx-react"
import {IconButton} from "@cling/lib.web.mdc"

export const ToggleSortBoardsAlphabetically = observer(({display}: {display: "icon" | "link"}) => {
    const {sort_boards_alphabetically} = current_user.account_settings
    const on_click = React.useCallback(() => {
        runInAction(
            () =>
                (current_user.account_settings.sort_boards_alphabetically =
                    !sort_boards_alphabetically),
        )
        call_function(
            new PatchFullAccountRequest({
                patch_uid: create_PatchUID(),
                patch_account_settings: new PatchAccountSettings({
                    sort_boards_alphabetically: sort_boards_alphabetically
                        ? TriState.false
                        : TriState.true,
                }),
            }),
        ).catch(report_error)
    }, [sort_boards_alphabetically])
    if (display === "link") {
        return (
            <a className="action" onClick={on_click}>
                {sort_boards_alphabetically
                    ? i18n.recently_modified_first
                    : i18n.sort_alphabetically}
            </a>
        )
    } else {
        return (
            <IconButton
                icon="sort_by_alpha"
                small
                onClick={on_click}
                outlined
                className={sort_boards_alphabetically ? "" : "mdcx-icon--not-pressed"}
                tooltip={
                    sort_boards_alphabetically
                        ? i18n.sort_alphabetically
                        : i18n.recently_modified_first
                }
                data-test-id="ToggleSortBoardsAlphabetically"
            />
        )
    }
})
